

type global = {
    videoBlob?:any,
    concatStatus:any,
    isBetaVersion:boolean,
    googleTrackingID:string


}
const GLOBAL:global = {
    // videoBlob:null,
    concatStatus: {
        unprocessed: 'UNPROCESSED',
        provisioning: 'PROVISIONING',
        completed: 'COMPLETED',
        failed: 'FAILED'
    },
    isBetaVersion: false,
    googleTrackingID: 'UA-172406011-1'
}

export default GLOBAL
