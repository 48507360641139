import React, { useState } from "react";
import Layout from "../../components/Layout";
import TextField from "@mui/material/TextField";
import redClock from "../../assets/images/redclock.png";
import redcalender from "../../assets/images/redcalender.png";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import questionmark from "../../assets/images/questionmark.png";
import * as Strings from "../../constants/strings";
import Api from "../../api/api";
import { EventType, OccasionType } from "../../util/types";
import MyLoader from "../../util/MyLoader";
type Props = {};

const CreateEvent = (props: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  let occasion: any = location.state;
  const [title, setTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [eventDate, setEventDate] = useState<Date | null>(new Date());
  const [eventTime, setEventTime] = useState<Date | null>(new Date());
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(15);
  const handleDateChange = (newValue: Date | null) => {
    setEventDate(newValue);
  };
  const handleTimeChange = (newValue: Date | null) => {
    setEventTime(newValue);
  };
  const getEventDeadline = () => {
    let d1 = moment(eventDate).format("MMM DD YYYY");
    let d2 = moment(eventTime).format("H:mm:ss");
    //console.log(d1);
    //console.log(d2);
    return d1 + " " + d2;
    // return moment(date).format('ddd DD MMM YYYY') + '@' + moment(date).format('h:mm a')
  };

  const saveEvent = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // getEventDeadline()
    if (title === "") {
      setShowError(true);
      setError(Strings.txt15);
      return;
    }
    if (message === "") {
      setShowError(true);
      setError(Strings.txt16);
      return;
    }
    // if (eventDateStr === '' || eventTimeStr === '') {
    //     setShowError(true)
    //     setError(Strings.txt17)
    //     return
    // }
    let date = new Date(eventDate + " " + eventTime);
    if (moment().valueOf() > date.getTime()) {
      setShowError(true);
      setError(Strings.txt72);
      return;
    }
    setLoader(true);
    Api.createEvent(
      occasion.id,
      title,
      message,
      getEventDeadline(),
      timer,
      (resposne: EventType) => {
        //console.log("response: " + JSON.stringify(resposne));
        setLoader(false);
        if (resposne.id) {
          localStorage.setItem("eventAdded", "true");
          navigate("/dashboard", { replace: true });
        } else {
          setShowError(true);
          setError(Strings.txt53);
        }
      },
      (error: any) => {
        setLoader(false);
        setShowError(true);
        setError("" + error);
      }
    );
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Layout>
        <div className="flex flex-col w-full items-center gap-4">
          <h1 className="text-[#565f8f] text-xl font-[500]">
            Step <span className="text-[#ef3d55]">2</span> 0f 2{" "}
          </h1>
          <form
            onSubmit={(e) => {
              saveEvent(e);
            }}
            className="flex flex-col px-5 gap-4 w-full max-w-[560px]"
          >
            <div className="flex flex-col gap-1">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                name="text"
                id="title"
                className="py-2 px-4 w-full rounded-md border-[1px] border-solid border-[#aeb5d9] text-[#505050] text-lg"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter event name"
              />
            </div>
            <div className="flex flex-col gap-1">
              <label htmlFor="message">Message for the contributors</label>

              <textarea
                rows={6}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                name="text"
                id="message"
                className="py-2 px-4 w-full rounded-md border-[1px] border-solid border-[#aeb5d9] text-[#505050]"
                placeholder="Tell participants about the event"
              ></textarea>
            </div>

            <div className="flex gap-2 mt-2">
              <p className="">Event Deadline</p>
              <img className="w-[17px] h-[17px]" src={questionmark} alt="" />
            </div>
            <div className="flex mt-[-8px] bg-white border-solid border-[#aeb5d9] border-[1px] px-5 rounded-md ">
              <label
                htmlFor="date"
                className="w-full flex relative gap-4 cursor-pointer items-center"
              >
                <img src={redcalender} width={27} height={27} alt="" />
                <MobileDatePicker
                  // label="Date mobile"
                  // inputFormat='mm/dd/yyyy'
                  value={eventDate}
                  onChange={handleDateChange}
                  disablePast
                  renderInput={(params) => <TextField id="date" {...params} />}
                />
              </label>
              <label
                htmlFor="time"
                className="w-full flex relative gap-1 cursor-pointer items-center"
              >
                <img src={redClock} width={27} height={27} alt="" />
                <MobileTimePicker
                  // label="Date mobile"
                  inputFormat="h:mm a"
                  value={eventTime}
                  onChange={handleTimeChange}
                  className="w-full"
                  renderInput={(params) => <TextField id="time" {...params} />}
                />
              </label>
            </div>
            <div className="w-full flex-col flex gap-4">
              <div className="flex gap-2 mt-2">
                <p className="font-[400]">Timer(30 sec max)</p>
                <img className="w-[17px] h-[17px]" src={questionmark} alt="" />
              </div>
              <input
                style={{
                  alignSelf: "stretch",
                  marginLeft: 0,
                  marginRight: 20,
                  marginTop: 0,
                  borderRadius: 15,
                }}
                type="range"
                min="5"
                max="30"
                step="5"
                value={"" + timer}
                className="slider"
                id="myRange"
                onChange={(event) => {
                  setTimer(parseInt(event.target.value));
                }}
              ></input>
              <p className=" text-black">{timer} Seconds</p>
            </div>
            {showError && (
              <p className=" text-[#ef3d55]  text-center">{error}</p>
            )}
            <div className="w-[96%] mx-auto flex gap-4 mt-4 justify-center px-2">
              <button
                onClick={() => navigate(-1)}
                type="button"
                className="py-2 w-full px-4 bg-[#ef3d55] text-white text-xl font-bold rounded-md"
              >
                Cancel
              </button>
              <button
                // onClick={() => navigate("/choose_event")}
                type="submit"
                className="py-2 w-full px-4 bg-[#565f8f] text-white text-xl font-bold rounded-md"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        <MyLoader loader={loader} />
      </Layout>
    </LocalizationProvider>
  );
};

export default CreateEvent;
