import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/Layout";
import cam from "../../assets/images/cam.png";
import GLOBAL from "../../constants/global";
import VideoRecorder from "react-video-recorder";
import { useNavigate } from "react-router-dom";
import back from "../../assets/images/back_white.png";
import change_cam from "../../assets/images/change_cam.png";
import { is } from "date-fns/locale";

type Props = {};
type toggletype = {
  exact: string;
};
const Record = (props: Props) => {
  const navigate = useNavigate();
  const [facingMode, setFacingMode] = useState<string | toggletype>("user");
  const [showToggle, setShowToggle] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showCam, setShowCam] = useState(true);
  const [countDown, setCountdown] = useState<number>(0);
  const recorder = useRef<any>(null);
  const isFacebookBrowser = /FBAN|FBAV/i.test(navigator.userAgent);

  let event: any = localStorage.getItem("event");
  if (event != null) {
    event = JSON.parse(event);
  } else {
    event = {};
  }
  return (
    <Layout>
      <div className="flex-col items-center flex gap-4 w-full max-w-[480px] mx-auto px-2">
        <div className="mx-auto">
          <div className="flex w-full gap-2 items-center mt-4">
            <img width={35} height={35} src={event?.occasionIcon} alt={""} />
            <h1 className="text-2xl font-semibold text-[#565f8f]">
              Record Video
            </h1>
          </div>
        </div>
        <h4 className="text-lg font-medium text-gray-700">{event?.title}</h4>
        { isFacebookBrowser && (
          <div className="p-4 w-full mb-[-5px] text-sm xsm:text-md bg-white text-center text-gray-600">
            If you opened this with facebook and are getting an error, please
            'open in browser' instead
          </div>
        )}
        
        <div className="w-full rounded-md overflow-hidden">
          {showCam && (
            <VideoRecorder
              ref={(ref: any) => {
                recorder.current = ref;
              }}
              isFlipped={false}
              timeLimit={event.maxTimeInSeconds * 1000}
              constraints={{
                audio: true,
                video: {
                  facingMode: facingMode,
                },
              }}
              onTurnOnCamera={() => {
                //console.log("onTurnOnCamera");
                setShowToggle(true);
                recorder.current.startRecording();
              }}
              onStartRecording={() => {
                //console.log("onStartRecording");
                setShowToggle(false);
              }}
              onStopRecording={() => {
                //console.log("onStopRecording");
                setShowToggle(false);
              }}
              onRecordingComplete={async (videoBlob: any) => {
                // Do something with the video...
                //console.log("videoBlob", videoBlob);
                GLOBAL.videoBlob = videoBlob;
                //console.log("onStopRecording");
                setShowToggle(false);
                if (GLOBAL.videoBlob != null) {
                  setShowNext(true);
                } else {
                  setShowError(true);
                }
              }}
              onError={(error: any) => {
                console.log("onError", error);
                console.log("onStopRecording");
                setShowToggle(false);
              }}
              renderDisconnectedView={() => {
                return (
                  <img
                    src={cam}
                    style={{ width: 100, height: 100, marginBottom: 100 }}
                  />
                );
              }}
            />
          )}
        </div>
        {showToggle ? (
          <button
            onClick={() => {
              //console.log("click");
              if (facingMode === "user") {
                setFacingMode({
                  exact: "environment",
                });
              } else {
                setFacingMode("user");
              }
              if (recorder.current != null) {
                recorder.current.turnOffCamera();
                setTimeout(() => {
                  recorder.current.turnOnCamera();
                }, 500);
              }
            }}
            style={{
              display: "flex",
              alignSelf: "center",
              width: 50,
              height: 50,
              borderRadius: 25,
              alignItems: "center",
              justifyContent: "center",
              marginTop: 10,
            }}
            className="cam-toggle"
          >
            <img src={change_cam} style={{ width: 40, height: 40 }} />
          </button>
        ) : null}
        <div className="w-full flex mt-2 justify-between  gap-7 items-center">
          <button
            onClick={() => navigate(-1)}
            type="button"
            className="py-2  h-auto sm:h-[51px] text-md sm:text-[18px] font-bold flex items-center justify-center w-full max-w-[170px] sm:max-w-[208px] gap-2 bg-[#565f8f] text-white rounded-md"
          >
            Cancel
          </button>
          {showNext && (
            <button
              onClick={() => navigate("/upload_video")}
              type="button"
              className="py-2 h-auto sm:h-[51px] text-md sm:text-[18px] font-bold flex items-center justify-center w-full max-w-[170px] sm:max-w-[208px] gap-2 bg-[#ef3d55] text-white rounded-md"
            >
              Next
              <img
                width={30}
                height={30}
                className="scale-[-1] mb-[2px]"
                src={back}
                alt="back"
              />
            </button>
          )}
        </div>
      </div>
    </Layout>
  );
};
export default Record;