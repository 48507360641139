import React, { useState } from 'react'
import logintop1 from "../../assets/images/logintop1.png";
import logintop from "../../assets/images/logintop.png";
import loginbottom from "../../assets/images/loginbottom.png";
import logo from "../../assets/images/logo.png";
import emailLogo from "../../assets/images/email.png";
import lock from "../../assets/images/lock.png";
import {AiOutlineEye,AiOutlineEyeInvisible} from "react-icons/ai";
import googlelogo from "../../assets/images/googlelogo.png";
import userIcon from "../../assets/images/usericon.png";
import Auth from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'
import * as Strings from '../../constants/strings';
import { Navigate, useNavigate } from "react-router-dom";
import MyLoader from '../../util/MyLoader';

const SignUp = () => {
    const [passwordHide,setPasswordHide] = useState<boolean>(true);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [name, setName] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const checkEmail = (email:string) => {
        let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return emailReg.test(email);
    }

    const socialLogin = (provider:any) => {
        setLoader(true)
        Auth.federatedSignIn({ provider }).then(credentials => {
            //console.log('credentials: ', credentials);
        }).catch(e => {
            console.log('error: ' + JSON.stringify(e));
        })
    }

    const showErrorMsg = (error:string) => {
        setError(error)
        setShowError(true)
        setTimeout(() => {
            setError('')
            setShowError(false)
        }, 2000);
    };

    const hideErrorMsg = () => {
        setError('');
        setShowError(false)
    };
   

        const signupUser = (e:React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            if (name === '') {
                showErrorMsg(Strings.txt43)
                return
            }
            if (email === '') {
                showErrorMsg(Strings.txt38)
                return
            }
            if (!checkEmail(email)) {
                showErrorMsg(Strings.txt44)
                return
            }
            if (password === '') {
                showErrorMsg(Strings.txt39)
                return
            }
            if (password.length < 6) {
                showErrorMsg(Strings.txt48)
                return
            }
    
            hideErrorMsg()
            setLoader(true)
            Auth.signUp({
                username: email,
                password: password,
                attributes: {
                    name: name
                },
                validationData: [], // optional
            })
                .then((data) => {
                    setLoader(false)
                    //console.log(data);
                    navigate('/verify_email',{
                        state: { email_: email, password_: password }
                    })
                    // this.props.navigation.navigate('VerifyEmail', { email: this.state.email, password: this.password })
                })
                .catch((err) => {
                    setLoader(false)
                    if (err.message) {
                        showErrorMsg(err.message)
                    }
                    console.log(err);
                })
        };

        if (localStorage.getItem('login') || localStorage.getItem("user")) {
           return <Navigate to={"/dashboard"} replace />
        }
  return (
    <div className='layout w-full max-w-[600px] min-h-screen pb-0 mb-0 flex flex-col bg-[#eaedff] mx-auto justify-center items-center relative overflow-hidden'>
        <div className='absolute top-[-40px] left-[-37px]' >
            <img src={logintop1} alt="logintop" />
        </div>
        <div className='absolute top-[-40px] left-[-40px]' >
            <img src={logintop} alt="logintop" />
        </div>
        <div className='absolute bottom-4 right-[-50px]' >
            <img src={loginbottom} alt="loginbottom" />
        </div>

        <div className='w-full h-full flex flex-col justify-start my-10 items-center gap-6 px-6'>
            <div className='w-[184px] h-[140px]'>
                <img className='object-cover h-full w-full' src={logo} alt="logo" />
            </div>

            <div className='text-center space-y-3'>
                <p className='text-xl tracking-wide'>Signup now and start your own video greeting</p>
                <h1 className='font-semibold text-2xl'>Sign Up</h1>
            </div>

            <form onSubmit={(e)=>signupUser(e)} className='flex flex-col items-center gap-5 w-full max-w-[460px] px-4 mb-32' >

                <label htmlFor='name' className='flex bg-white rounded-md w-full max-w-[448px] py-4 px-4 gap-5 items-center h-[57px]' >
                    <img className='w-[28px] h-[20px] ' src={userIcon} alt="" />
                    <input id="name" type="text" placeholder='Your Name' className='w-full bg-transparent outline-none h-full border-none text-black font-semibold tracking-wide' value={name} onChange={(e)=>setName(e.target.value)} />
                </label>
                <label htmlFor='email' className='flex bg-white rounded-md w-full max-w-[448px] py-4 px-4 gap-5 h-[57px] items-center' >
                    <img className='w-[28px] h-[20px] ' src={emailLogo} alt="" />
                    <input id="email" type="email" placeholder='Your Email' className='w-full bg-transparent h-full outline-none border-none text-black font-semibold tracking-wide' value={email} onChange={(e)=>setEmail(e.target.value)} />
                </label>

                <label htmlFor='password' className='flex bg-white rounded-md w-full max-w-[448px] py-4 px-4 gap-5 items-center h-[57px]' >
                    <img className='w-[28px] h-[20px] flex-grow-1 ' src={lock} alt="" />
                    <input id="password" type={passwordHide?"password":"text"} placeholder='Password' className='w-full h-full bg-transparent outline-none border-none text-black font-semibold tracking-wide' value={password} onChange={(e)=>setPassword(e.target.value)}  />
                    {passwordHide?<AiOutlineEyeInvisible size={40} onClick={()=>setPasswordHide(state=>!state)}
                    />:<AiOutlineEye size={40} onClick={()=>setPasswordHide(state=>!state)} />}
                </label>

                {/* <div className='w-full text-left' >
                    <p className='font-semibold hover:underline cursor-pointer'>Forgot Password?</p>
                </div> */}

                {showError&&<p className='text-red-600 font-semibold text-xl'>{error}</p>}

                <button type='submit' className='w-full font-bold text-xl text-white tracking-widest bg-[#ef3d55] py-3 rounded-md' >Sign Up</button>
                <p className='text-center text-lg'>Or Sign In with</p>
                <button onClick={()=>socialLogin("Google")} type='button' className='w-full flex justify-center items-center font-bold text-xl text-white tracking-widest bg-white py-2 rounded-md'>
                    <img src={googlelogo} alt="" />
                </button>

                <p className='text-center z-[1000]  mt-4'>Already have an account? <span onClick={()=>navigate("/login")} className='underline font-semibold cursor-pointer' >Signin</span> </p>

            </form>

        </div>


        <footer className='absolute bottom-0 left-0 p-4 w-full bg-[#565f8f] text-white flex justify-center items-center' >
                <p>© 2023 VideoCorral - All Rights Reserved</p>    
        </footer>
        <MyLoader loader={loader} />
    </div>
  )
}

export default SignUp