import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as Colors from '../../constants/colors'
import background from '../../assets/images/background.png';
import logo from '../../assets/images/logo.png';
import Layout from '../../components/Layout';
type Props = {}

const Splash = (props: Props) => {
    const navigate = useNavigate();




    useEffect(()=>{
        let eventId = localStorage.getItem('eventId')
        if (window.location.href.includes('?event')) {
            //console.log('if');
            eventId = window.location.href.split('event=')[1]
            //console.log('eventId: ' + eventId);
            localStorage.setItem('eventId', eventId);
            setTimeout(() => {
               navigate('/about',{replace:true})
            }, 500);
        }
        else if (localStorage.getItem('login') != null) {
            //console.log('else if');
            navigate('/dashboard',{replace:true})
        }
    },[])



    return (
        <Layout hideBackButton={true}>
            <div className='text-center mt-20 '>
                <h1 className='bg-red-500  text-white px-3 py-2 rounded-lg'>Please wait a moment!</h1>
            </div>
        </Layout>
    );
}

export default Splash