import React, { useState } from "react";
import Layout from "../../components/Layout";
import aboutusericon from "../../assets/images/aboutusericon.png";
import usericon from "../../assets/images/usericon.png";
import { useNavigate } from "react-router-dom";
import * as ApiConstants from '../../api/api'
import MyLoader from "../../util/MyLoader";
type Props = {};

const About = (props: Props) => {
  const navigate = useNavigate();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState<string>('');
  const [loader,setLoader] = useState<boolean>(false);

  let eventId:any=localStorage.getItem("eventId")
  if (window.location.href.includes('?event')) {
      eventId = window.location.href.split('event=')[1]
  }
  //console.log('eventId: ' + eventId);
  localStorage.setItem('eventId', eventId);
  const registerContributor = (e:React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (name === '')
        return
    // if (isSubscribing)
    //     return
    // let emailReg:RegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // if (isSubscribing && !emailReg.test(email)) {
    //     console.log('wrong email');
    //     setError('Invalid Email!')
    //     setShowError(true)
    //     setTimeout(() => {
    //         setError('')
    //         setShowError(false)
    //     }, 3000);
    //     return
    // }
    //console.log('registerContributor');
    setLoader(true)
    let url = ApiConstants.registerContributor
    let params = {
        referenceName: name,
        email:email,
        isSubscribing: isSubscribing,
    }
    let headers = {
        'Content-Type': 'application/json'
    }
    //console.log('url: ' + url);
    if (params != null)
        //console.log('sending: ' + JSON.stringify(params));
    fetch(url, {
        method: 'POST',
        headers: headers,
        body: params == null ? params : JSON.stringify(params)
    })
        .then(response => response.json())
        .then(result => {
            setLoader(false)
            if (result.authToken) {
                //console.log('token: ' + result.authToken);
                localStorage.setItem('token', result.authToken);
                navigate('/overview');
            }
            else {
                setError(result.message)
                setShowError(true)
                setTimeout(() => {
                    setError('')
                    setShowError(false)
                }, 3000);
            }
        })
        .catch(err => {
            console.log(err)
        });
};
  return (
    <Layout>
      <div className="flex flex-col gap-4 items-center">
        <div className="w-full flex justify-center items-center gap-3">
            <img width={40} height={40} src={aboutusericon} alt="about" />
            <h1 className="text-xl font-bold text-[#565f8f]">About Me</h1>
        </div>
        <form onSubmit={(e)=>registerContributor(e)} className="w-full px-4 max-w-[460px] mx-auto flex flex-col items-start gap-4">
        <h5 className="text-[#505050] text-sm xsm:text-[15px] text-center sm:text-md font-[500]" >Let the organizer know you're submitting videos</h5>
            <div className="w-full relative h-[50px] xsm:h-[56px] sm:h-[61px] flex justify-center items-center " >
                <input className="pl-12 py-3 pr-2 bg-white  w-full h-full border-[1px] border-solid border-[#aeb5d9] rounded-[5px]" placeholder="First Name" type="text" name="firstname" value={name} onChange={(e)=>setName(e.target.value)}  />
                <img className="absolute top-5 left-4" src={usericon} alt="" />
            </div>
           {showError && <p className="text-lg text-[#ef3d55]" >{error}</p>}

            <button type="submit" className='w-full py-2 h-auto xsm:h-[45px] sm:h-[56px] bg-[#ef3d55] text-white font-bold  text-lg sm:text-[22px] rounded-md '>
                    Let's Go
            </button>
        </form>
      </div>
      <MyLoader loader={loader} />
    </Layout>
  );
};

export default About;
