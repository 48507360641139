import React, { useState } from "react";
import logintop from "../../assets/images/logintop.png";
import logintop1 from "../../assets/images/logintop1.png";
import loginbottom from "../../assets/images/loginbottom.png";
import logo from "../../assets/images/logo.png";
import emailLogo from "../../assets/images/email.png";
import lock from "../../assets/images/lock.png";
import googlelogo from "../../assets/images/googlelogo.png";
import Auth from "@aws-amplify/auth";
import { Hub } from "@aws-amplify/core";
import * as Strings from "../../constants/strings";
import { Navigate, useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import MyLoader from "../../util/MyLoader";

const Login = () => {
  const [passwordHide, setPasswordHide] = useState<boolean>(true);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showError, setShowError] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const showErrorMsg = (error: string) => {
    setError(error);
    setShowError(true);
    setTimeout(() => {
      setError("");
      setShowError(false);
    }, 2000);
  };

  const hideErrorMsg = () => {
    setError("");
    setShowError(false);
  };

  const socialLogin = (provider: any) => {
    setLoader(true);
    Auth.federatedSignIn({ provider })
      .then((credentials) => {
        //console.log("credentials: ", credentials);
        setLoader(false);
      })
      .catch((e) => {
        console.log(e);
        console.log("error: " + JSON.stringify(e));
        setLoader(false);
      });
  };
  const authListener = ({ payload: { event, result } }: any) => {
    switch (event) {
      case "signIn":
        setLoader(true);
        //console.log("signIn User: ", result);
        break;
      case "cognitoHostedUI":
        setLoader(true);
        //console.log("cognitoHostedUI User: ", result);
        break;
      case "signOut":
        break;
      case "signIn_failure":
        console.log("signIn_failure");
        setLoader(false);
        break;
      case "cognitoHostedUI_failure":
        console.log("cognitoHostedUI_failure: " + JSON.stringify(result));
        setLoader(false);
        break;
      case "parsingCallbackUrl":
        console.log("parsingCallbackUrl:" + result);
    }
  };
  Hub.listen("auth", () => {
    //console.log("hello");
  });
  Auth.currentAuthenticatedUser()
    .then((user) => {
      // console.log('current user: ' + JSON.stringify(user));
      const session = user.getSignInUserSession();
      // console.log('Login session: ', session);
      let accessToken = session.accessToken.jwtToken;
      let provider = "";
      if (session.idToken.payload.identities) {
        provider = session.idToken.payload.identities[0].providerName;
      }
      console.log("provider: " + provider);
      let user_: any = {
        name: session.idToken.payload.name,
        email: session.idToken.payload.email,
        social:
          provider === "Facebook" ||
          provider === "Google" ||
          provider === "SignInWithApple",
      };
      // console.log('store user: ' + JSON.stringify(user_));
      localStorage.setItem("login", "true");
      localStorage.setItem("user", JSON.stringify(user_));
      localStorage.setItem("accessToken", accessToken);
      navigate("/dashboard", { replace: true });
      Hub.remove("auth", () => {
        console.log("hello");
      });
    })
    .catch(() => console.log("Not signed in"));

  const moveToHome = (e: React.FormEvent<HTMLFormElement>) => {
    setLoader(true);
    e.preventDefault();
    //console.log(email, password);
    if (email === "") {
      showErrorMsg(Strings.txt38);
      return;
    }
    if (password === "") {
      showErrorMsg(Strings.txt39);
      return;
    }
    hideErrorMsg();
    Auth.signIn(email, password)
      .then((result) => {
        let accessToken = result.signInUserSession.accessToken.jwtToken;
        let user = {
          name: result.signInUserSession.idToken.payload.name,
          email: result.signInUserSession.idToken.payload.email,
          social: false,
        };
        //console.log("token: " + accessToken);
        //console.log("user: " + JSON.stringify(user));
        localStorage.setItem("login", "true");
        localStorage.setItem("user", JSON.stringify(user));
        localStorage.setItem("accessToken", accessToken);
        setLoader(false);
        navigate("/dashboard", { replace: true });
      })
      .catch((err) => {
        setLoader(false);
        if (!err.message) {
          showErrorMsg(Strings.txt53);
        } else {
          if (err.code === "UserNotConfirmedException") {
            console.log("User not confirmed");
          }
          if (err.message) {
            console.log(err.message);
            showErrorMsg(err.message);
          }
        }
      });
  };

  if (localStorage.getItem("login") || localStorage.getItem("user")) {
    return <Navigate to={"/dashboard"} replace />;
  }
  return (
    <div className=" layout w-full max-w-[600px] min-h-screen pb-0 mb-0 flex flex-col bg-[#eaedff] mx-auto justify-center items-center relative overflow-hidden">
      <div className="absolute top-[-40px] left-[-37px]">
        <img src={logintop1} alt="logintop" />
      </div>
      <div className="absolute top-[-40px] left-[-40px]">
        <img src={logintop} alt="logintop" />
      </div>
      <div className="absolute bottom-4 right-[-50px]">
        <img src={loginbottom} alt="loginbottom" />
      </div>

      <div className="w-full h-full flex flex-col justify-start my-10 items-center gap-6 px-6">
        <div className="w-[184px] h-[140px]">
          <img className="object-cover h-full w-full" src={logo} alt="logo" />
        </div>

        <div className="text-center space-y-1">
          <h1 className="font-semibold text-3xl">Welcome Back</h1>
          <p className="text-2xl tracking-wide mt-2">Sign In</p>
        </div>

        <form
          onSubmit={(e) => moveToHome(e)}
          className="flex flex-col items-center gap-5 w-[96%] max-w-[456px] px-4 mb-32"
        >
          <label
            htmlFor="email"
            className="flex bg-white h-[61px] rounded-[5px] w-full max-w-[448px]  p-4 gap-5 items-center"
          >
            <img className="w-[24px] h-[17px] " src={emailLogo} alt="" />
            <input
              id="email"
              type="email"
              placeholder="Your Email"
              className="w-full bg-transparent h-full outline-none border-none text-black font-semibold tracking-wide text-lg"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>

          <label
            htmlFor="password"
            className="flex bg-white  h-[61px] rounded-[5px] w-full max-w-[448px] p-4 gap-5 items-center"
          >
            <img className="w-[19px] h-[26px] flex-grow-1 " src={lock} alt="" />
            <input
              id="password"
              type={passwordHide ? "password" : "text"}
              placeholder="Password"
              className="w-full bg-transparent outline-none border-none text-black font-semibold tracking-wide text-lg"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordHide ? (
              <AiOutlineEyeInvisible
                color={"#2d2d2dd8"}
                size={40}
                onClick={() => setPasswordHide((state) => !state)}
              />
            ) : (
              <AiOutlineEye
                color={"#2d2d2dd8"}
                size={40}
                onClick={() => setPasswordHide((state) => !state)}
              />
            )}
          </label>

          <div className="w-full text-left">
            <p
              onClick={() => navigate("/forgot_password")}
              className="hover:underline cursor-pointer font-[500] text-[15px] text-black"
            >
              Forgot Password?
            </p>
          </div>

          {showError && (
            <p className="text-red-600 font-semibold text-xl">{error}</p>
          )}

          <button
            type="submit"
            className="w-full font-bold text-xl text-white  bg-[#ef3d55] py-3 h-[61px] rounded-[5px]"
          >
            Sign In
          </button>
          <p className="text-center text-[16px] font-[400]">Or Sign In with</p>
          <button
            onClick={() => socialLogin("Google")}
            type="button"
            className="w-full flex justify-center h-[61px] items-center font-bold text-xl text-white tracking-widest bg-white py-2 rounded-[5px]"
          >
            <img src={googlelogo} alt="" />
          </button>

          <p className="text-center z-[1000] text-[16px] font-[400] mt-4">
            Don't have an account?{" "}
            <span
              onClick={() => navigate("/signup")}
              className="underline font-medium cursor-pointer"
            >
              Signup
            </span>{" "}
          </p>
        </form>
      </div>

      <footer className="absolute bottom-0 left-0 p-4 w-full bg-[#565f8f] text-white flex justify-center items-center">
        <p>© 2023 VideoCorral - All Rights Reserved</p>
      </footer>
      <MyLoader loader={loader} />
    </div>
  );
};

export default Login;
