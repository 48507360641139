import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";

import { useNavigate } from "react-router-dom";
import Api from "../../api/api";
import Utils from "../../util/utils";
import { OccasionType } from "../../util/types";
import MyLoader from "../../util/MyLoader";
const ChooseEvent = () => {
  const navigate = useNavigate();
  const [eventIndex, setEventIndex] = useState<number>(-1);
  const [events, setEvents] = useState<OccasionType[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  let session = Utils.checkSession();
  if (!session) {
    navigate("/login", { replace: true });
  }

  const sortOccasions = (occasions: any) => {
    let sorted = occasions.sort((a: any, b: any) => {
      return a.title.length > b.title.length;
    });
    return sorted;
  };

  const loadOccasions = async () => {
    setLoader(true);
    let occasions: any = localStorage.getItem("occasions");
    if (occasions != null) {
      occasions = JSON.parse(occasions);
      if (occasions.length > 0) {
        let sorted = sortOccasions(occasions);
        setEvents(sorted);
      }
    }
    Api.getOccasions(
      (models: OccasionType[]) => {
        localStorage.setItem("occasions", JSON.stringify(models));
        let sorted = sortOccasions(models);
        setLoader(false);
        setEvents(sorted);
      },
      (error: any) => {
        setLoader(false);
        console.log(error);
      }
    );
  };

  useEffect(() => {
    if (events.length == 0) loadOccasions();
  }, []);
  //console.log(events);
  return (
    <Layout>
      <div className="flex flex-col w-full items-center gap-4">
        <p className="text-[#565f8f] text-xl font-[500]">
          Step <span className="text-red-600">1</span> of 2{" "}
        </p>
        <h1 className=" text-[#565f8f] text-xl sm:text-[25px] tracking-wide font-[700]">
          What's the special occasion?
        </h1>

        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 px-[1px] xsm:px-3">
          {events?.map((event) => (
            <div
              onClick={() => navigate("/create_event", { state: event })}
              className="w-[160px] h-[130px] mx-2 flex flex-col justify-center items-center gap-4 bg-white rounded-md cursor-pointer transition-all duration-200 ease-in-out hover:scale-105"
            >
              <img
                width={45}
                height={45}
                src={event.iconUrl}
                alt={"birthday"}
              />
              <p className="font-medium text-black">{event.title}</p>
            </div>
          ))}
        </div>
      </div>
      <MyLoader loader={loader} />
    </Layout>
  );
};

export default ChooseEvent;
