import React, { useState } from "react";
import Layout from "../../components/Layout";
import fireworks from "../../assets/images/fireworks.png";
import { useLocation, useNavigate } from "react-router-dom";
import * as ApiConstants from "../../api/api";
import GLOBAL from "../../constants/global";
import axios from "axios";
import MyLoader from "../../util/MyLoader";
var Buffer = require("buffer/").Buffer;

type Props = {};

const Upload = (props: Props) => {
  const navigate = useNavigate();
  // const location =useLocation();
  // console.log(location.state,"jkjk");
  const [loader, setLoader] = useState(false);
  const [uploader, setUpLoader] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const [uploadFail, setUploadFail] = useState(false);
  const [progress, setProgress] = useState<any>(0);

  const randomFixedNumber = (length: number) => {
    return Math.floor(
      Math.pow(10, length - 1) +
        Math.random() * (Math.pow(10, length) - Math.pow(10, length - 1) - 1)
    );
  };

  const uploadVideo = () => {
    setLoader(true);
    let videoName = randomFixedNumber(6) + ".mp4";
    //console.log("videoName: " + videoName);
    let params = {
      fileName: videoName,
    };
    let url = ApiConstants.getUploadUrl + "/" + localStorage.getItem("eventId");
    let headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    //console.log("uploadVideo: " + url);
    fetch(url, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(params),
    })
      .then((response) => response.json())
      .then((response) => {
        setLoader(false);
        //console.log("url: " + response.url);
        if (response.url) {
          let uploadUrl = response.url;
          uploadVideoFile(uploadUrl);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  const uploadVideoFile = async (uploadUrl: string) => {
    setUploaded(false);
    setUploadFail(false);
    setProgress(1);
    setUpLoader(true);
    // console.log('convert');
    //console.log("blob: " + GLOBAL.videoBlob);
    var arrayBuffer = await GLOBAL.videoBlob.arrayBuffer();
    //console.log("arryBuffer: " + arrayBuffer);
    const buffer = Buffer.from(arrayBuffer, "binary");
    //console.log("buffer: " + buffer);
    // setProgress(20)
    //console.log("uploadVideoFile");
    axios
      .request({
        method: "put",
        url: uploadUrl,
        headers: {
          "Content-Type": "video/webm",
        },
        data: buffer,
        onUploadProgress: (p) => {
          //console.log("onUploadProgress:");
          //console.log("loaded: " + p.loaded);
          //console.log("total: " + p.total);
          //console.log("progress: " + progress);
          //console.log("ratio: " + p.loaded / p.total);
          let currentProgress: any = (p.loaded / p.total) * 100;
          setProgress(parseInt(currentProgress));
        },
      })
      .then((data) => {
        //console.log("then:");
        //console.log(JSON.stringify(data));
        setUploaded(true);
        setProgress(100);
        setUpLoader(false);
      })
      .catch((err) => {
        console.log("catch:" + JSON.stringify(err));
        setUploaded(false);
        setUploadFail(true);
        setProgress(0);
        setUpLoader(false);
      });
  };
  //console.log(GLOBAL.videoBlob);
  return (
    <Layout>
      <div className="flex flex-col items-center gap-4 px-3 w-full max-w-[470px] mx-auto">
        <h1 className="text-2xl mt-2 font-bold text-[#565f8f]">Upload Video</h1>
        <div className="flex flex-col items-center gap-2 mt-1">
          <img
            className="w-[115px] sm:w-[124px] h-[115px] sm:h-[124px]"
            src={fireworks}
            alt="fireworks"
          />
          <p className="text-[#ef3d55] text-xl xsm:text-2xl sm:text-2xl font-bold">
            Almost There!
          </p>
        </div>
        <div className="w-full text-center bg-white text-gray-700 p-5 rounded-md ">
          {uploaded ? (
            <p className="text-lg font-semibold">Uploaded Successfully</p>
          ) : (
            <div className="text-sm">
              {progress < 100 && uploader ? (
                <p className="text-lg font-semibold">Uploading...</p>
              ) : (
                <div className="text-sm">
                  <p> Now it’s time to upload your videos.</p>
                  <p> (Wifi recommended).</p>
                </div>
              )}
            </div>
          )}
        </div>
        {uploadFail && <h1>Upload failed! Retry</h1>}

        {progress > 0 && !uploadFail && (
          <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div
              className="bg-blue-600 h-2.5 rounded-full"
              style={{
                width: `${progress}%`,
              }}
            ></div>
          </div>
        )}

        {!uploaded && (
          <div className="w-full mt-5 flex justify-between gap-4">
            <button
              onClick={() => navigate(-1)}
              type="button"
              className="py-2 h-[40px] xsm:h-[48px] sm:h-[51px] flex items-center justify-center px-2 w-full max-w-[170px] sm:max-w-[201px] gap-2 bg-[#565f8f] text-white font-semibold text-md sm:text-lg tracking-wide rounded-md"
            >
              Back
            </button>
            <button
              type="button"
              onClick={() => uploadVideo()}
              className="py-2 h-[40px] xsm:h-[48px] sm:h-[51px] flex items-center justify-center px-2 w-full max-w-[170px] sm:max-w-[201px] gap-2 bg-[#ef3d55] text-white  font-semibold text-md sm:text-lg tracking-wide rounded-md"
            >
              Upload
            </button>
          </div>
        )}
        {uploaded && (
          <button
            type="button"
            onClick={() => navigate("/dashboard")}
            className="py-2 h-[40px] xsm:h-[48px] sm:h-[51px] flex items-center justify-center px-2 w-full max-w-[170px] sm:max-w-[201px] gap-2 bg-[#565f8f] text-white  font-semibold text-md sm:text-lg tracking-wide rounded-md"
          >
            Back
          </button>
        )}
        <div
          onClick={() => navigate("/choose_event")}
          className="w-full max-w-[450px] bg-[#ef3d55] text-white p-5 font-semibold mt-5 cursor-pointer text-center rounded-md"
        >
          Start your own VideoCorral
        </div>
      </div>
      <MyLoader loader={loader} />
    </Layout>
  );
};

export default Upload;
