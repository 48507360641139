import React, { useState } from 'react'
import logintop from "../../assets/images/logintop.png";
import logintop1 from "../../assets/images/logintop1.png";
import loginbottom from "../../assets/images/loginbottom.png";
import logo from "../../assets/images/logo.png";
import Auth from '@aws-amplify/auth'
import * as Strings from '../../constants/strings';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ReactGA from 'react-ga';
import MyLoader from '../../util/MyLoader';


const SignUp = () => {
    const location = useLocation();
    const navigate = useNavigate();
    let {email_,password_}:any=location.state
    //console.log(email_,password_);

    const [email, setEmail] = useState(email_);
    const [password, setPassword] = useState(password_);
    const [code, setCode] = useState('');
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');
    const [loader,setLoader] = useState<boolean>(false);

    const verifyCode = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoader(true)
        await Auth.confirmSignUp(email, code)
            .then(() => {
                setLoader(false)
                ReactGA.event({
                    category: 'User',
                    action: 'New User Account'
                });
                loginUser()
            })
            .catch((err) => {
                setLoader(false)
                if (!err.message) {
                    setError(Strings.txt53)
                    setShowError(true)
                } else {
                    setError(err.message)
                    setShowError(true)
                }
            });
    };

    const loginUser = () => {
        //console.log('loginUser');
        setError('');
        setShowError(false)
        setLoader(true)
        Auth.signIn(email, password)
            .then((result) => {
                setLoader(false)
                let accessToken = result.signInUserSession.accessToken.jwtToken
                let user = {
                    name: result.signInUserSession.idToken.payload.name,
                    email: result.signInUserSession.idToken.payload.email,
                    social: false,
                }
                //console.log('token: ' + accessToken);
                //console.log('user: ' + JSON.stringify(user));
                localStorage.setItem('login', 'true');
                localStorage.setItem('user', JSON.stringify(user));
                localStorage.setItem('accessToken', accessToken);
                navigate('/dashboard',{replace:true})
            })
            .catch((err) => {
                setLoader(false)
                if (!err.message) {
                    setError(Strings.txt53);
                    setShowError(true)
                } else {
                    if (err.message) {
                        setError(err.message);
                        setShowError(true)
                    }
                }
            })
    };

    const resendCode = async () => {
        setLoader(true)
        await Auth.resendSignUp(email)
            .then(() => {
                setLoader(false)
            })
            .catch((err) => {
                setLoader(false)
                if (!err.message) {
                    setError(Strings.txt53)
                    setShowError(true)
                } else {
                    setError(err.message)
                    setShowError(true)
                }
            });
    };

       
    if (localStorage.getItem('login') || localStorage.getItem("user")) {
        return <Navigate to={"/dashboard"} replace />
     }
  return (
    <div className='layout w-full max-w-[600px] min-h-screen pb-0 mb-0 flex flex-col bg-[#eaedff] mx-auto justify-center items-center relative overflow-hidden'>
        <div className='absolute top-[-40px] left-[-37px]' >
            <img src={logintop1} alt="logintop" />
        </div>
        <div className='absolute top-[-40px] left-[-40px]' >
            <img src={logintop} alt="logintop" />
        </div>
        <div className='absolute bottom-4 right-[-50px]' >
            <img src={loginbottom} alt="loginbottom" />
        </div>

        <div className='w-full h-full flex flex-col justify-start my-10 items-center gap-6 px-6'>
            <div className='w-[184px] h-[140px]'>
                <img className='object-cover h-full w-full' src={logo} alt="logo" />
            </div>

            <div className='text-center space-y-1 mb-2'>
                <h1 className='font-semibold text-2xl'>Verify Email</h1>
            </div>

            <form onSubmit={(e)=>verifyCode(e)} className='flex flex-col items-center gap-5 w-full max-w-[460px] px-4 mb-32' >

                <label htmlFor='name' className='flex bg-white rounded-md w-full max-w-[448px] py-5 px-5 gap-5 items-center' >
                    <input id="code" type="text" placeholder='Enter The Code' className='w-full bg-transparent outline-none border-none text-black font-semibold tracking-wide' value={code} onChange={(e)=>setCode(e.target.value)} />
                </label>
                <button type='submit' className='w-full font-bold text-xl mt-2 text-white tracking-widest bg-[#ef3d55] py-3 rounded-md' >Verify</button>
                {showError&&<p className='text-red-600 font-semibold text-xl'>{error}</p>}

                <p onClick={()=>resendCode()} className='text-center hover:underline text-2xl tracking-wider cursor-pointer font-semibold' >Resend</p>


            </form>

        </div>


        <footer className='absolute bottom-0 left-0 p-4 w-full bg-[#565f8f] text-white flex justify-center items-center' >
                <p>© 2023 VideoCorral - All Rights Reserved</p>    
        </footer>
        <MyLoader loader={loader} />
    </div>
  )
}

export default SignUp