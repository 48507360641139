import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import Layout from '../../components/Layout'
import ReactPlayer from "react-player";
type Props = {}

const PreviewVideo = (props: Props) => {
  const location = useLocation()
  const {event,video}:any = location.state
  //console.log({event,video})
  return (
    <Layout>
      <div className='flex flex-col gap-4 items-center' >
        <h1 className='text-xl font-bold text-[#565f8f]'>Video Preview</h1>
        <div className='w-[95%] max-w-[500px] flex justify-center' >
          <div className='rounded-md overflow-hidden flex justify-center'>
        <ReactPlayer
                        style={{marginTop:10}}
                        url={video.previewUrl}
                        playing={true}
                        controls={true} />
          </div>
        </div>

      </div>

    </Layout>
  )
}

export default PreviewVideo