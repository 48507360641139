import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Api from '../../api/api'
import Layout from '../../components/Layout'
import MyLoader from '../../util/MyLoader'
import GLOBAL from '../../constants/global'
import * as Colors from '../../constants/colors'
import ReactPlayer from "react-player"
import cart from "../../assets/images/cart.png";
import download from "../../assets/images/download.png";
type Props = {}

const PreviewPurchase = (props: Props) => {
    const navigate = useNavigate()
    const location = useLocation()
    let eventId:string|null;
    if(location.state) {
        const {event}:any = location.state
        eventId = event.id
    } else if (window.location.href.includes('?event')) {
        eventId =  new URLSearchParams(window.location.search).get(
            "event"
        );
    }
    const [streamingUrl, setStreamingUrl] = useState<string>('');
    const [previewUrl, setPreviewUrl] = useState<string>('');
    const [title, setTitle] = useState<string>('');
    const [purchased, setPurchased] = useState<boolean>(false);
    const [statusMessage, setStatusMessage] = useState('');
    const [concatStatus, setConcatStatus] = useState<string>('UNPROCESSED');
    const [loadingPreview, setLoadingPreview] = useState<boolean>(false);
    const [loader, setLoader] = useState<boolean>(false);

    const previewVideo = () => {
        navigate('/video_preview')
    };

    const loadPreview = (eventId:string) => {
        setLoader(true)

        Api.getEventDetail(eventId,
            (event:any) => {
                console.log(JSON.stringify(event));
                setLoader(false)
                setConcatStatus(event.concatStatus);
                setPurchased(event.isPurchased);
                setTitle(event.title);
                if (event.concatStatus === 'UNPROCESSED') {
                    setLoader(false)
                    setStatusMessage('Video merging has been started. You will get an email notification once merging is finished.');
                    startConvertJob(eventId)
                } else if(event.concatStatus === 'COMPLETED') {
                    setStreamingUrl(event.streamingUrl)
                    setPreviewUrl(event.previewUrl)
                } else if(event.concatStatus === 'FAILED') {
                    setStatusMessage('Sorry something wrong. Please try with creating new event');
                }
                else {
                    // TODO need to handle the failed status
                }
            },
            (error:any) => {
                setLoader(false)
            });
    };

    const startConvertJob = (eventId:string) => {
        Api.startConvertJob(eventId,
            (response:any) => {
                //console.log(JSON.stringify(response));
            },
            (error:any) => {
                // setLoader(false)
            });
    };

    const getCheckoutURL = (eventId:string|null) => {
        setLoader(true);
        Api.getStripeSessionUrl(eventId,
            (response:any) => {
                window.location = response.sessionUrl;
                setLoader(false);
            },
            (error:any) => {
                // setLoader(false)
            });
    };

    const downloadVideo = (url:string, fileName:string) => {
        Api.downloadVideo(url, fileName);
    }

    useEffect(() => {
        if(eventId!==null) loadPreview(eventId);
    }, [])
  return (
    <Layout>
        <div className='flex flex-col items-center gap-4 ' >
            <h1 className='text-xl font-bold text-[#565f8f]'>{(purchased || GLOBAL.isBetaVersion) ? 'Share Video' : 'Preview'}</h1>
            <div className='w-[95%] max-w-[480px]' >
                <h1 className='text-lg font-semibold ml-2' >
                    {title}
                </h1>
                {((purchased || GLOBAL.isBetaVersion) &&  concatStatus === 'COMPLETED' && previewUrl) &&
                <h2 className='text-lg font-semibold text-[#717aac] text-center mt-4'>Share</h2>
                }
                {(concatStatus !== 'COMPLETED' && previewUrl === '') && 
                <div className='w-[95%] max-w-[480px] mx-auto bg-white p-4' >
                    {statusMessage}
                </div>
                }
                { concatStatus === 'COMPLETED' && previewUrl ?
                <div className='w-[96%] max-w-[500px] max-h-[300px] mx-auto flex justify-center' >
                    <ReactPlayer
                    style={{marginBottom:40,height:'auto'}}
                        url={previewUrl}
                        playing={true}
                        controls={true} />
                </div>
                        : null
                    }
                {!GLOBAL.isBetaVersion && !purchased &&  concatStatus === 'COMPLETED' && previewUrl?
                        <div 
                        style={{
                            marginTop: 20,
                            marginBottom: 50,
                            display: 'flex',
                            justifyContent: 'center',
                            flex: 1,
                            paddingBottom: 30,
                            backgroundColor: Colors.background,
                            flexDirection: 'column',
                            alignItems: 'stretch',
                            alignSelf: 'stretch',
                            width: "100%"
                        }}>
                            <div
                                style={{
                                    backgroundColor: Colors.theme,
                                    height: 45,
                                    borderRadius: 10,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}
                                className='tab-btn'
                                onClick={() => {
                                    // history.push('/stripe_payment', { eventId: eventId })
                                    getCheckoutURL(eventId)
                                }}>
                                <img
                                    style={{width: 25, height: 25}}
                                    src={cart}/>
                                <div style={{color: 'white', fontSize: 16, marginLeft: 10}}>
                                    Purchase
                                </div>
                            </div>
                        </div>
                        : null
                    }

                    {(purchased || GLOBAL.isBetaVersion) &&  concatStatus === 'COMPLETED' && previewUrl ?
                                <div className="w-full mt-20 flex justify-center" onClick={() => { downloadVideo(previewUrl,`${title}.mp4`)}}>
                                    <img
                                        style={{ width: 55, height: 55}}
                                        src={download} />
                                </div>
                                         : null
                    }
            </div>

        </div>
        <MyLoader loader={loader}/>
    </Layout>
  )
}

export default PreviewPurchase