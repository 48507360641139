import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../api/api";
import Layout from "../../components/Layout";
import play from "../../assets/images/play.png";
import deletevideo from "../../assets/images/deletevideo.png";
import GLOBAL from "../../constants/global";
import preview from "../../assets/images/preview.png";
import MyLoader from "../../util/MyLoader";

type Props = {};

const EventVideo = (props: Props) => {
  const location = useLocation();
  let { event }: any = location.state;
  //console.log({ event });
  const [videos, setVideos] = useState<any>([]);
  const [purchased, setPurchased] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(true);
  const navigate = useNavigate();
  const loadVideos = async (model: any) => {
    setLoader(true);
    let event: any = localStorage.getItem("event" + model.id);
    if (event != null) {
      event = JSON.parse(event);
      if (event.videos.length > 0) {
        setLoader(false);
        setVideos(event.videos);
        setPurchased(event.isPurchased);
      }
    }
    Api.getEventVideos(
      model.id,
      (response: any) => {
        setLoader(false);
        //console.log(JSON.stringify(response));
        localStorage.setItem("event" + model.id, JSON.stringify(response));
        setVideos(response.videos);
        setPurchased(response.isPurchased);
      },
      (error: any) => {
        setLoader(false);
        console.log(error);
      }
    );
  };
  const previewVideo = (index: number) => {
    navigate("/video_preview", {
      state: { event: event, video: videos[index] },
    });
  };
  const deleteVideo = (videoId: string) => {
    setLoader(true);
    Api.deleteVideo(
      videoId,
      (response: any) => {
        Api.getEventVideos(
          event.id,
          (response: any) => {
            localStorage.setItem("event" + event.id, JSON.stringify(response));
            setVideos(response.videos);
            setLoader(false);
          },
          (error: any) => {
            setLoader(false);
            console.log(error);
          }
        );
      },
      (error: any) => {
        setLoader(false);
        console.log(error);
      }
    );
  };
  const previewPurchase = () => {
    if (videos.length == 0) return;
    navigate("/preview_purchase", {
      state: { event: event },
    });
  };
  useEffect(() => {
    loadVideos(event);
  }, []);
  //console.log({ videos });
  return (
    <Layout>
      <div className="w-full flex-col flex gap-4 items-center px-5">
        <h1 className="text-xl font-bold text-[#565f8f]">Review</h1>
        <button
          type="submit"
          className="w-full py-2 bg-[#565f8f] text-white text-lg rounded-md"
        >
          Contributor Uploads
        </button>
          <div className="grid grid-cols-2 gap-4 w-full  place-items-center">
            {videos.length > 0 ? (
              videos?.map((video: any, index: number) => (
                <div
                  key={video.id}
                  className="w-full bgBeforeBlack max-w-[265px] h-auto max-h-[235px] rounded-md overflow-hidden relative"
                >
                  <img
                    onClick={() => {
                      previewVideo(index);
                    }}
                    src={video.previewThumbnail}
                    className={`w-full h-full object-cover object-center`}
                    alt=""
                  />
                  <div className="flex items-center w-full justify-between py-5 px-2 sm:px-5 absolute bottom-0 left-0">
                    <div
                      onClick={() => {
                        previewVideo(index);
                      }}
                      className="flex gap-1 xsm:gap-2 sm:gap-3 items-center cursor-pointer"
                    >
                      <img className="scale-75 xsm:scale-95 sm:scale-100  " src={play} alt="" />
                      <p className="text-lg xsm:text-xl sm:text-2xl text-white">PLAY</p>
                    </div>
                    <div
                      onClick={() => {
                        deleteVideo(video.id);
                      }}
                      className="cursor-pointer"
                    >
                      <img className="scale-75 xsm:scale-95 sm:scale-100" src={deletevideo} alt="" />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex w-full justify-center p-5 ">
                <p className="text-xl font-semibold">No Videos To Show</p>
              </div>
            )}
          </div>
        {videos.length > 0 && (
          <button
            onClick={() => {
              previewPurchase();
            }}
            type="button"
            className="w-full mt-8 flex items-center justify-center gap-4 py-2 bg-[#ef3d55] text-white text-lg rounded-md">
            <img src={preview} alt="" />
            <p className="text-white text-lg">
              {purchased || GLOBAL.isBetaVersion
                ? "Preview"
                : "Preview & Purchase"}
            </p>
          </button>
        )}
      </div>
      <MyLoader loader={loader} />
    </Layout>
  );
};

export default EventVideo;
