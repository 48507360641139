const awsConfig = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ee1ec4a9-3aa9-4339-a61a-9e0b3c69d161",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_TO1kKeJKm",
    "aws_user_pools_web_client_id": "6n2d15stok9t9rgfc4edpf19hp",
    "oauth": {
        "domain": "videocorral.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
         "redirectSignIn": "https://app.videocorral.com/login",
         "redirectSignOut": "https://app.videocorral.com/login",
        //"redirectSignIn": "http://localhost:3000/login",
        //"redirectSignOut": "http://localhost:3000/login",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
};

export default awsConfig;
