import React from 'react'
import { Navigate } from 'react-router-dom'

interface protectedRoute{
    children : JSX.Element
}
const ProtectedRoutes = ({children}:protectedRoute) => {
    const login = localStorage.getItem("login")
    const user = localStorage.getItem("user")
    if(login && user){
        return (
            children
        )
    }
    return <Navigate to={"/login"} replace />

}

export default ProtectedRoutes