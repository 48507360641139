import React from "react";
import logo from "../assets/images/mainlogo.png";
import logotext from "../assets/images/logotext.png";
import backWhite from "../assets/images/back_white.png";
import homeIcon from "../assets/images/home.png";
import add_corral from "../assets/images/add_corral.png";
import add_corral_white from "../assets/images/add_corral_white.png";
import user_gray from "../assets/images/user_gray.png";
import gear_gray from "../assets/images/gear_gray.png";
import user_white from "../assets/images/user_white.png";
import gear_white from "../assets/images/gear_white.png";
import bell from "../assets/images/bell.png";
import { AiFillHome } from "react-icons/ai";
import { useLocation, useNavigate } from "react-router-dom";

interface layoutProps {
  children?: React.ReactNode;
  hideBackButton?:boolean;
}

const Layout = ({ children,hideBackButton }: layoutProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  let active = "add_corral";
  if (location.pathname.endsWith("/dashboard")) {
    active = "home";
  }
  if (location.pathname.endsWith("/settings")) {
    active = "settings";
  }
  if (location.pathname.endsWith("/profile")) {
    active = "profile";
  }
  if (location.pathname.endsWith("/notifications")) {
    active = "notifications";
  }

  return (
    <div className="layout w-full max-w-[600px] h-screen flex flex-col bg-[#eaedff] mx-auto  relative overflow-hidden">
      <div
        className={`header mb-5 w-full h-[120px] px-4 flex items-center justify-between`}
      >
        <div className="flex gap-2">
          <div className="w-[57px] h-[57px] mb-5">
            <img
              className="object-contain w-full h-full "
              src={logo}
              alt="logo"
            />
          </div>
          <div className="mt-2">
            <img src={logotext} alt="" />
          </div>
        </div>
        {!hideBackButton&&<div
          onClick={() => navigate(-1)}
          className="flex gap-2 mb-3 items-center cursor-pointer hover:scale-105"
        >
          <img className="w-[33px] h-[18px]" src={backWhite} alt="back" />
          <p className="text-white text-xl font-[500] tracking-wide">Back</p>
        </div>}
        
      </div>
      <div className="w-full absolute bottom-0 left-0">
        <nav className="bg-[#484f78] w-full">
          <ul className="w-full h-full flex justify-between items-center p-0 m-0">
            <li
              onClick={() => navigate("/dashboard")}
              className={`w-full flex justify-center items-center cursor-pointer py-4 px-2 bg-transparent h-full hover:bg-[#ef3d55] ${
                active === "home" && "bg-[#ef3d55]"
              }`}
            >
              <AiFillHome
                size={30}
                color={active === "home" ? "#fff" : "#6974b6"}
              />
            </li>
            <li
              onClick={() => navigate("/choose_event")}
              className={`w-full flex justify-center items-center cursor-pointer py-4 px-2 bg-transparent h-full hover:bg-[#ef3d55] ${
                active === "add_corral" && "bg-[#ef3d55]"
              }`}
            >
              <img
                className="w-[30px] h-[30px]"
                src={active === "add_corral" ? add_corral_white : add_corral}
                alt=""
              />
            </li>
            <li
              onClick={() => navigate("/notifications")}
              className={`w-full flex justify-center items-center cursor-pointer py-4 px-2 bg-transparent h-full hover:bg-[#ef3d55] ${
                active === "notifications" && "bg-[#ef3d55]"
              }`}
            >
              <img className="w-[30px] h-[30px]" src={bell} alt="" />
            </li>
            <li
              onClick={() => navigate("/settings")}
              className={`w-full flex justify-center items-center cursor-pointer py-4 px-2 bg-transparent h-full hover:bg-[#ef3d55] ${
                active === "settings" && "bg-[#ef3d55]"
              }`}
            >
              <img
                className="w-[30px] h-[30px]"
                src={active === "settings" ? gear_white : gear_gray}
                alt=""
              />
            </li>
            <li
              onClick={() => navigate("/profile")}
              className={`w-full flex justify-center items-center cursor-pointer py-4 px-2 bg-transparent h-full hover:bg-[#ef3d55] ${
                active === "profile" && "bg-[#ef3d55]"
              }`}
            >
              <img
                className="w-[30px] h-[30px]"
                src={active === "profile" ? user_white : user_gray}
                alt=""
              />
            </li>
          </ul>
        </nav>
        <footer className="p-4 w-full text-sm xsm:text-md bg-[#565f8f] text-white flex justify-center items-center">
          <p>© 2023 VideoCorral - All Rights Reserved</p>
        </footer>
      </div>
      <main className="h-[calc(100vh-125.8px)] overflow-y-scroll noscrollbar mb-[180px] px-4 sm:px-9">
        {children}
      </main>
    </div>
  );
};

export default Layout;
