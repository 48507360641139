import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import moment from "moment";
import Modal from "@mui/material/Modal";
import * as Strings from "../../constants/strings";
import { IoMdVideocam } from "react-icons/io";
import facebook from "../../assets/images/facebook.png";
import whatsapp from "../../assets/images/whatsapp.png";
import twitter from "../../assets/images/twitter.png";
import linkedin from "../../assets/images/linkedin.png";
import copylink from "../../assets/images/copylink.png";
import videorecorder from "../../assets/images/video_icon.png";
import {
  BsFillGearFill,
  BsFillCheckCircleFill,
  BsFillTrashFill,
  BsShareFill,
} from "react-icons/bs";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";
import { useNavigate } from "react-router-dom";
import Api from "../../api/api";
import { useOutsideClick } from "../../hooks/outsideHandler";
import { EventType } from "../../util/types";
import MyLoader from "../../util/MyLoader";
const Dashboard = () => {
  const [models, setModels] = useState<EventType[]>([]);
  const [modal, setModal] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(2);
  const [currentEventUrl, setCurrentEventUrl] = useState<string>("");
  const [currentEventUrlText, setCurrentEventUrlText] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const navigate = useNavigate();
  const modalRef = useOutsideClick(() => {
    setModal(false);
    setCurrentEventUrl("");
  });

  const checkProfileInfo = async () => {
    let user_: any = localStorage.getItem("user");
    if (user_ != null) {
      user_ = JSON.parse(user_);
    } else {
      user_ = {};
    }
    let noName = false;
    let noEmail = false;
    let oldUser = user_;
    if (!user_.name || user_.name == null || user_.name === "") {
      if (oldUser != null) {
        if (oldUser.name == null || oldUser.name === "") noName = true;
        else user_.name = oldUser.name;
      } else {
        noName = true;
      }
    }
    if (!user_.email || user_.email == null || user_.email === "") {
      if (oldUser != null) {
        if (oldUser.email == null || oldUser.email === "") noEmail = true;
        else user_.email = oldUser.email;
      } else {
        noEmail = true;
      }
    }
    if (noName || noEmail) {
      navigate("/missing_info", {
        state: { showName: noName, showEmail: noEmail },
      });
    }
    localStorage.setItem("user", JSON.stringify(user_));
  };

  const handleSharebtn = (shareUrl: string, shareText: string) => {
    setModal(true);
    setCurrentEventUrl(shareUrl);
    setCurrentEventUrlText(shareText);
  };

  const loadEvents = async () => {
    let events: any = localStorage.getItem("events");
    if (events != null) {
      events = JSON.parse(events);
      if (events.length > 0) {
        setLoader(false);
        setSelectedTab(2);
        setModels(events);
      }
    }
    setLoader(true);
    Api.getEvents(
      (models: EventType[]) => {
        localStorage.setItem("events", JSON.stringify(models));
        setLoader(false);
        setSelectedTab(2);
        // let test = [
        //     models[0],models[0],models[0],models[0],models[0],models[0],models[0]
        // ]
        setModels(models);
      },
      (error: any) => {
        setLoader(false);
        console.log(error);
      }
    );
  };
  const checkInProgress = (event: any) => {
    return !event.isPurchased;
  };

  const deleteEvent = (event: any) => {
    // show confirm alert
    let confirm = window.confirm(Strings.txt68);
    if (confirm) {
      setLoader(true);
      Api.deleteEvent(
        event.id,
        (response: EventType[]) => {
          //console.log("delete response: " + JSON.stringify(response));
          setLoader(false);
          const newModels = models.filter((item, j) => {
            return item.id !== event.id; //false will delete
          });
          localStorage.setItem("events", JSON.stringify(newModels));
          setModels(newModels);
        },
        (error: any) => {
          setLoader(false);
          // showErrorToast('' + error)
          console.log(error);
        }
      );
    }
  };

  const recordVideo = (event: any) => {
    // check if past event
    let deadlineStr = moment(moment.utc(event.deadline)).format(
      "MM/DD/YYYY H:mm:ss"
    );
    let deadlineDate = new Date(deadlineStr);
    if (deadlineDate.getTime() > moment().valueOf()) {
      console.log("dealine not gone");
      localStorage.setItem("eventId", event.id);
      navigate("/about");
    } else {
      alert(Strings.txt75);
    }
  };
  const openVideos = (event: EventType) => {
    let pathName: string = "/event_videos";

    // if already merge and purchase video no need to show the contributor videos. Directly navigate to montage
    if (event.isPurchased) {
      pathName = "/preview_purchase";
    }
    navigate(pathName, {
      state: { event: event },
    });
  };

  const getModels = () => {
    if (selectedTab === 1) {
      return models;
    }
    let modelsNew: any[] = [];
    for (let i = 0; i < models.length; i++) {
      const event = models[i];
      if (selectedTab === 2 && checkInProgress(event)) {
        modelsNew = modelsNew.concat(event);
      } else if (selectedTab === 3 && !checkInProgress(event)) {
        modelsNew = modelsNew.concat(event);
      }
    }
    return modelsNew;
  };

  useEffect(() => {
    checkProfileInfo();
    loadEvents();
  }, []);
  //console.log(models);
  return (
    <Layout>
      <Modal open={modal}>
        <div className="absolute w-screen h-screen flex justify-center items-center">
          <div
            className={`w-full max-w-[600px] h-full flex justify-center items-center `}
          >
            <div
              ref={modalRef}
              className="w-[96%] py-10 px-3 sm:px-5 md:px-10 bg-white flex gap-6 justify-center items-center flex-col"
            >
              <h1 className="text-xl tracking-wide font-semibold">
                Share Event
              </h1>
              <div className="flex w-full flex-wrap sm:flex-nowrap justify-center items-center gap-8">
                <FacebookShareButton
                  url={currentEventUrlText}
                  children={
                    <div className="cursor-pointer flex flex-col gap-2 items-center">
                      <img
                        className="w-[45px] sm:w-[60px] h-[45px] sm:h-[60px]"
                        src={facebook}
                        alt=""
                      />
                      <p className="font-[500 text-sm md:text-md]">Facebook</p>
                    </div>
                  }
                />
                <TwitterShareButton
                  url={currentEventUrlText}
                  children={
                    <div className="cursor-pointer flex flex-col gap-2 items-center">
                      <img
                        className="w-[45px] sm:w-[60px] h-[45px] sm:h-[60px]"
                        src={twitter}
                        alt=""
                      />
                      <p className="font-[500] text-sm md:text-md">Twitter</p>
                    </div>
                  }
                />
                <WhatsappShareButton
                  url={currentEventUrlText}
                  children={
                    <div className="cursor-pointer flex flex-col gap-2 items-center">
                      <img
                        className="w-[45px] sm:w-[60px] h-[45px] sm:h-[60px]"
                        src={whatsapp}
                        alt=""
                      />
                      <p className="font-[500] text-sm md:text-md">Whatsapp</p>
                    </div>
                  }
                />
                <LinkedinShareButton
                  url={currentEventUrlText}
                  children={
                    <div className="cursor-pointer flex flex-col gap-2 items-center">
                      <img
                        className="w-[45px] sm:w-[60px] h-[45px] sm:h-[60px]"
                        src={linkedin}
                        alt=""
                      />
                      <p className="font-[500] text-sm md:text-md">Linked In</p>
                    </div>
                  }
                />
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(currentEventUrl);
                    setModal(false);
                  }}
                  className="cursor-pointer items-center flex flex-col gap-2"
                >
                  <img
                    className="w-[45px] sm:w-[60px] h-[45px] sm:h-[60px]"
                    src={copylink}
                    alt=""
                  />
                  <p className="font-[500] text-sm md:text-md">Copy Link</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <div className="w-full min-h-full flex flex-col items-center gap-5">
        <h1 className="text-2xl font-bold capitalize text-[#565f8f]">
          Dashboard
        </h1>
        <div className="w-full mt-2 ">
          <div className="w-full flex justify-between items-end gap-3">
            <div
              className={`w-full  hover:bg-[#ef3d55] cursor-pointer text-black hover:text-white flex items-center justify-center py-3 px-[1px] transition-all duration-200 ease-out gap-2 rounded-sm ${
                selectedTab === 1 ? "bg-[#ef3d55] text-[#fff]" : "bg-white"
              } rounded-tr-md rounded-tl-md w-[190px]`}
              onClick={() => setSelectedTab(1)}
            >
              <div className="">
                {selectedTab === 1 ? (
                  <img src={videorecorder} alt="recorder" />
                ) : (
                  <div className="text-[15px] xsm:text-[18px] sm:text-[22px]">
                    <IoMdVideocam />
                  </div>
                )}
              </div>
              <p className="capitalize font-semibold text-[12px] xsm:text-sm sm:text-md md:text-lg tracking-wider">
                All
              </p>
            </div>
            <div
              className={`w-full  hover:bg-[#ef3d55] cursor-pointer text-black hover:text-[#fff] flex items-center justify-center py-3 px-[1px] transition-all duration-200 ease-out gap-2 rounded-sm ${
                selectedTab === 2 ? "bg-[#ef3d55] text-[#fff]" : "bg-white"
              } rounded-tr-md rounded-tl-md`}
              onClick={() => setSelectedTab(2)}
            >
              <div className="text-[15px] xsm:text-[18px] sm:text-[22px]">
                <BsFillGearFill />
              </div>
              <p className="capitalize  font-semibold text-[12px] xsm:text-sm sm:text-md md:text-lg tracking-wider">
                in progress
              </p>
            </div>
            <div
              className={`w-full  hover:bg-[#ef3d55] cursor-pointer text-black hover:text-white flex items-center justify-center py-3 px-[1px] transition-all duration-200 ease-out gap-2 rounded-sm ${
                selectedTab === 3 ? "bg-[#ef3d55] text-[#fff]" : "bg-white"
              } rounded-tr-md rounded-tl-md`}
              onClick={() => setSelectedTab(3)}
            >
              <div className="text-[15px] xsm:text-[18px] sm:text-[22px]">
                <BsFillCheckCircleFill />
              </div>
              <p className="capitalize font-semibold text-[12px] xsm:text-sm sm:text-md md:text-lg tracking-wider">
                purchased
              </p>
            </div>
          </div>

          <div className="w-full bg-[#ef3d55]  h-[3px]"></div>

          <div className=" w-full rounded-sm bg-white pt-4 px-3 space-y-3 h-auto max-h-[350px] pb-8 overflow-y-scroll noscrollbar">
            {models &&
              getModels().length > 0 &&
              getModels().map((event: EventType) => {
                let shareUrl: string =
                  "https://app.videocorral.com?event=" + event.id;
                let shareText: string = event.title + ": " + shareUrl;
                return (
                  <div className="w-full flex-wrap xsm:flex-nowrap rounded-sm bg-[#ebeeff] p-3 gap-4 xsm:gap-3 flex justify-between items-center">
                    <div
                      onClick={() => {
                        openVideos(event);
                      }}
                      className="w-full flex-grow flex items-center justify-start xsm:justify-start gap-3 cursor-pointer"
                    >
                      <div className=" bg-white overflow-hidden  rounded-md p-4">
                        <img
                          className="object-cover w-full h-full max-h-[35px] max-w-[35px]"
                          src={event.occasionIcon}
                          alt="wedding"
                        />
                      </div>
                      <div className=" flex flex-col gap-1 ">
                        <h1 className="text-black text-[16px]  font-medium">
                          {event.title}
                        </h1>
                        <p className="text-xs text-[#ef3d55]  font-semibold capitalize">
                          Due Date:
                        </p>
                        <p className="text-[15px] font-[500] text-gray-600 text-xs ">
                          {moment
                            .utc(event.deadline)
                            .format("MMM DD YYYY h:mm a")}
                        </p>
                      </div>
                    </div>

                    <div className="flex justify-between gap-2">
                      <div
                        onClick={() => recordVideo(event)}
                        className="bg-white text-[22px] xsm:text-[18px] sm:text-[24px] hover:bg-[#ef3d55] p-3 xsm:p-2 sm:p-3 rounded-md cursor-pointer text-[#565f8f] hover:text-white "
                      >
                        <IoMdVideocam />
                      </div>
                      <div
                        onClick={() => deleteEvent(event)}
                        className="bg-white text-[22px] xsm:text-[18px] sm:text-[24px] hover:bg-[#ef3d55] p-3 xsm:p-2 sm:p-3 rounded-md cursor-pointer text-[#565f8f]  hover:text-white "
                      >
                        <BsFillTrashFill />
                      </div>
                      <div
                        onClick={() => handleSharebtn(shareUrl, shareText)}
                        className="bg-white text-[22px] xsm:text-[18px] sm:text-[24px] hover:bg-[#ef3d55] p-3 xsm:p-2 sm:p-3 rounded-md cursor-pointer text-[#565f8f]  hover:text-white "
                      >
                        <BsShareFill />
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          <div className="w-full text-center mt-16 ">
            <h1 className="text-[17px] font-[500]  text-black">
              Do you know someone who is celebrating a special event?
            </h1>
            <button
              onClick={() => navigate("/choose_event")}
              type="button"
              className="py-2 w-[95%] max-w-[355px] px-2 bg-[#ef3d55] mt-2  text-white text-md sm:text-lg font-[700] rounded-md"
            >
              Start a new VideoCorral
            </button>
          </div>
        </div>
      </div>
      <MyLoader loader={loader} />
    </Layout>
  );
};

export default Dashboard;
