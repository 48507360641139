export const appName = '';
export const introText1 = 'Video Greeting Cards Made Simple'
export const introText2 = "It's time to make memories, not trash."
export const txt1 = "What's the special occasion?"
export const txt2 = "Title"
export const txt3 = 'Give your event a title'
export const txt4 = 'Message for the contributors'
export const txt5 = 'Tell participants about the event'
export const txt6 = 'Event Deadline'
export const txt7 = 'Timer'
export const txt8 = '(30 sec max)'
export const txt9 = 'Seconds'
export const txt10 = 'Done'
export const txt11 = 'Choose Date & Time'
export const txt12 = 'The time when event will expire.'
export const txt13 = 'The maximum recording time of video in seconds.'
export const txt14 = 'OK'
export const txt15 = 'Please enter title'
export const txt16 = 'Please enter message'
export const txt17 = 'Please choose deadline'
export const txt18 = 'Event Created!'
export const txt19 = 'Step'
export const txt20 = 'Dashboard'
export const txt21 = 'All'
export const txt22 = 'In Progress'
export const txt23 = 'Purchased'
export const txt24 = 'Do you know someone who is celebrating a special event?'
export const txt25 = 'Start a new VideoCorral'
export const txt26 = 'Due Date'
export const txt27 = 'Welcome Back' 
export const txt28 = 'Sign In'
export const txt29 = 'Your email'
export const txt30 = 'Password'
export const txt31 = 'Forgot Password?'
export const txt32 = 'Sign In'
export const txt33 = 'Or Sign In With'
export const txt34 = 'Facebook'
export const txt35 = 'Google'
export const txt36 = "Don't have an account?"
export const txt37 = 'Sign Up'
export const txt38 = 'Please enter email'
export const txt39 = 'Please enter password' 
export const txt40 = 'Signup now and start your own video greeting'
export const txt41 = 'Your name'
export const txt42 = 'Skip'
export const txt43 = 'Please enter name'
export const txt44 = 'Invalid email'
export const txt45 = 'Get Started'
export const txt46 = 'Verify'
export const txt47 = 'Verify Email'
export const txt48 = 'Password length must be atleast 6 letters'
export const txt49 = 'Enter Code'
export const txt50 = 'Forgot Password'
export const txt51 = 'Submit'
export const txt52 = 'Please check your email for confirmation code'
export const txt53 = 'Request failed!'
export const txt54 = 'Resend Code'
export const txt55 = 'Please check your email'
export const txt56 = 'New Password'
export const txt57 = 'Change Password'
export const txt58 = 'Password changed!'
export const txt59 = 'My Profile'
export const txt60 = 'Update'
export const txt61 = 'Logout'
export const txt62 = 'Are you sure you want to logout?'
export const txt63 = 'Cancel'
export const txt64 = 'Changes Saved'
export const txt65 = 'No Events Found'
export const txt66 = 'Choose'
export const txt67 = 'Delete Event'
export const txt68 = 'Are you sure you want to delete this event?'
export const txt69 = 'Yes'
export const txt70 = 'Apple'
export const txt71 = 'Save'
export const txt72 = 'Invalid date and time'
export const txt73 = 'Delete'
export const txt74 = 'Record a Video'
export const txt75 = 'Cannot add video to this event. Deadline is over!'
export const txt76 = 'Please enter code'
export const txt77 = 'Missing Info'