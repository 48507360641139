import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import * as ApiConstants from '../../api/api'
import back from "../../assets/images/back_white.png";
import { useNavigate } from 'react-router-dom';
import { EventType } from '../../util/types';
import MyLoader from '../../util/MyLoader';

type Props = {}

const Overview = (props: Props) => {
    const [event, setEvent] = useState<EventType>();
    const [loader,setLoader] = useState<boolean>(false);
    const navigate = useNavigate();

    const loadEvent = () => {
        //console.log('loadEvent');
        setLoader(true)
        let url = ApiConstants.events + '/' + localStorage.getItem('eventId');
        //console.log('url: '+url);
        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
        //console.log('url: ' + url);
        fetch(url, {
            method: 'GET',
            headers: headers,
            body: null
        })
            .then(response => response.json())
            .then(result => {
                //console.log(JSON.stringify(result));
                localStorage.setItem('event', JSON.stringify(result));
                setEvent(result)
                setLoader(false)
            })
            .catch(err => {
                console.log(err)
                setLoader(false)
            });
    };
    useEffect(() => {
        //console.log('useEffect');
        loadEvent()
    }, []);
  return (
    <Layout>

        {event && <div className='w-full flex flex-col gap-5 px-2 items-center'>
            <div className='flex gap-2 items-center'>
            <img width={35} height={35} src={event.occasionIcon} alt="" />
            <h1 className='text-2xl tracking-wide font-bold text-[#565f8f]' >Overview</h1>
            </div>

            <form className='flex flex-col max-w-[470px] items-center gap-3 w-full'>
                <div className='w-full ' >
                <h1 className='text-lg ml-3 text-[#505050] text-left font-bold'>{event.title}</h1>
                </div>
                <div className='w-full text-[#505050] tracking-wide p-6 border-[1px] border-solid border-[#aeb5d9] bg-white min-h-[200px] rounded-md'>{event.description}</div>
                <div className='w-full flex mt-2 justify-between items-center gap-4' >
                <button
                onClick={()=>navigate(-1)}
              type="button"
              className="py-2 flex items-center justify-center font-bold text-lg px-2 w-full max-w-[150px] xsm:max-w-[180px] sm:max-w-[208px] h-auto xsm:h-[45px] sm:h-[51px] gap-2 bg-[#565f8f] text-white rounded-md"
            >
              <img width={30} height={30} className='mb-[2px]' src={back} alt="back" />
              Previous
            </button>
                <button
              type="button"
              onClick={()=>navigate("/record")}
              className="py-2 flex items-center justify-center font-bold text-lg px-2 w-full max-w-[150px] xsm:max-w-[180px] sm:max-w-[208px] h-auto xsm:h-[45px] sm:h-[51px] gap-2 bg-[#ef3d55] text-white rounded-md"
            >
              Next
              <img width={30} height={30} className='scale-[-1] mb-[2px]' src={back} alt="back" />
            </button>

                </div>
            </form>
        </div>}
    <MyLoader loader={loader} />
    </Layout>
  )
}

export default Overview