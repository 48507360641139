import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import {
  Dashboard,
  ChooseEvent,
  CreateEvent,
  Login,
  Splash,
  Profile,
  About,
  EventVideo,
  Overview,
  Settings,
  Record,
  Upload,
  Signup,
  VerifyEmail,
  ForgotPassword,
  PreviewVideo,
  PreviewPurchase,
  Notifications,
} from "./pages";
import GLOBAL from "./constants/global";
import Amplify from "@aws-amplify/core";
import awsConfig from "./util/aws_config";
import ProtectedRoutes from "./components/ProtectedRoutes";
import ReactGA from "react-ga";


ReactGA.initialize(GLOBAL.googleTrackingID);

Amplify.configure(awsConfig);
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/verify_email" element={<VerifyEmail />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoutes>
              <Dashboard />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoutes>
              <Profile />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/about"
          element={
            <About />
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoutes>
              <Settings />
            </ProtectedRoutes>
          }
        />

        <Route
          path="/notifications"
          element={
            <ProtectedRoutes>
              <Notifications />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/record"
          element={
            <Record />
          }
        />
        <Route
          path="/upload_video"
          element={
            <Upload />
          }
        />
        <Route
          path="/choose_event"
          element={
            <ProtectedRoutes>
              <ChooseEvent />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/create_event"
          element={
            <ProtectedRoutes>
              <CreateEvent />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/event_videos"
          element={
            <ProtectedRoutes>
              <EventVideo />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/video_preview"
          element={
            <ProtectedRoutes>
              <PreviewVideo />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/preview_purchase"
          element={
            <ProtectedRoutes>
              <PreviewPurchase />
            </ProtectedRoutes>
          }
        />
        <Route
          path="/overview"
          element={
            <Overview />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
