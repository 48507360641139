import React, { useState } from 'react'
import logintop from "../../assets/images/logintop.png";
import logintop1 from "../../assets/images/logintop1.png";
import loginbottom from "../../assets/images/loginbottom.png";
import logo from "../../assets/images/logo.png";
import emailLogo from "../../assets/images/email.png";
import lock from "../../assets/images/lock.png";
import {AiOutlineEye,AiOutlineEyeInvisible} from "react-icons/ai";
import googlelogo from "../../assets/images/googlelogo.png";
import Auth from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'
import * as Strings from '../../constants/strings';
import { useNavigate } from "react-router-dom";
import MyLoader from '../../util/MyLoader';

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [showFields, setShowFields] = useState(false);
    const [showPass, setShowPass] = useState(false);
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [showError, setShowError] = useState(false);
    const [error, setError] = useState('');
    const [showMsg, setShowMsg] = useState(false);
    const [msg, setMsg] = useState('');
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();

    const checkEmail = (email:string) => {
        let emailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return emailReg.test(email);
    }

    const resetMsg = () => {
        setError('')
        setShowError(false)
        setMsg('')
        setShowMsg(false)
    };

    const sendCode = () => {
        resetMsg()
        if (email === '') {
            return
        }
        if (!checkEmail(email)) {
            setError(Strings.txt44)
            setShowError(true)
            return
        }

        setLoader(true)
        Auth.forgotPassword(email)
            .then(() => {
                setLoader(false)
                setShowFields(true)
                setMsg(Strings.txt55)
                setShowMsg(true)
            })
            .catch((err) => {
                setLoader(false)
                if (!err.message) {
                    setError(Strings.txt53)
                    setShowError(true)
                } else {
                    setError(err.message)
                    setShowError(true)
                }
            });
    };

    const forgotPasswordSubmit = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        resetMsg()
        if (email === '') {
            setError(Strings.txt38)
            setShowError(true)
            return
        }
        if (!checkEmail(email)) {
            setError(Strings.txt44)
            setShowError(true)
            return
        }
        if (code === '') {
            setError(Strings.txt76)
            setShowError(true)
            return
        }
        if (password === '') {
            setError(Strings.txt39)
            setShowError(true)
            return
        }
        if (password.length < 6) {
            setError(Strings.txt48)
            setShowError(true)
            return
        }

        setLoader(true)
        Auth.forgotPasswordSubmit(email, code, password)
            .then(() => {
                setLoader(false)
                setMsg(Strings.txt58)
                setShowMsg(true)
                navigate(-1)
            })
            .catch((err) => {
                setLoader(false)
                if (!err.message) {
                    setError(Strings.txt53)
                    setShowError(true)
                } else {
                    setError(err.message)
                    setShowError(true)
                }
            });
    };
    if (localStorage.getItem('login')) {
        navigate('/dashboard',{replace:true})
    }
  return (
    <div className='layout w-full max-w-[600px] min-h-screen pb-0 mb-0 flex flex-col bg-[#eaedff] mx-auto justify-center items-center relative overflow-hidden'>
        <div className='absolute top-[-40px] left-[-37px]' >
            <img src={logintop1} alt="logintop" />
        </div>
        <div className='absolute top-[-40px] left-[-40px]' >
            <img src={logintop} alt="logintop" />
        </div>
        <div className='absolute bottom-4 right-[-50px]' >
            <img src={loginbottom} alt="loginbottom" />
        </div>

        <div className='w-full h-full flex flex-col justify-start my-10 items-center gap-6 px-6'>
            <div className='w-[184px] h-[140px]'>
                <img className='object-cover h-full w-full' src={logo} alt="logo" />
            </div>

            <div className='text-center space-y-1'>
                <h1 className='font-semibold text-2xl'>Forgot Password?</h1>
            </div>

            <form onSubmit={(e)=>forgotPasswordSubmit(e)} className='flex flex-col items-center gap-5 w-full max-w-[460px] px-4 mb-32' >

                <label htmlFor='email' className='flex bg-white rounded-md w-full max-w-[448px] py-4 px-4 gap-5 items-center' >
                    <img className='w-[28px] h-[20px] ' src={emailLogo} alt="" />
                    <input id="email" type="email" placeholder='Your Email' className='w-full bg-transparent outline-none border-none text-black font-semibold tracking-wide' value={email} onChange={(e)=>setEmail(e.target.value)} />
                </label>
               {showFields && <><label htmlFor='name' className='flex bg-white rounded-md w-full max-w-[448px] py-5 px-5 gap-5 items-center' >
                    <input id="code" type="text" placeholder='Enter The Code' className='w-full bg-transparent outline-none border-none text-black font-semibold tracking-wide' value={code} onChange={(e)=>setCode(e.target.value)} />
                </label>

                <label htmlFor='password' className='flex bg-white rounded-md w-full max-w-[448px] py-4 px-4 gap-5 items-center' >
                    <img className='w-[28px] h-[20px] flex-grow-1 ' src={lock} alt="" />
                    <input id="password" type={showPass?"password":"text"} placeholder='Password' className='w-full bg-transparent outline-none border-none text-black font-semibold tracking-wide' value={password} onChange={(e)=>setPassword(e.target.value)}  />
                    {showPass?<AiOutlineEyeInvisible size={40} onClick={()=>setShowPass(state=>!state)}
                    />:<AiOutlineEye size={40} onClick={()=>setShowPass(state=>!state)} />}
                </label></> }

                {showError&&<p className='text-[#ef3d55] font-semibold text-xl'>{error}</p>}
                {showMsg &&<p className='text-green-800 font-semibold text-xl'>{msg}</p>}

                {showFields?<button type='submit' className='w-full font-bold text-xl text-white tracking-widest bg-[#ef3d55] py-3 rounded-md' >Change Password</button>
                :
                <button onClick={()=>sendCode()} type='button' className='w-full flex justify-center items-center font-bold text-xl text-white tracking-widest bg-[#ef3d55] py-2 rounded-md'>
                        Send OTP
                </button>}

            </form>

        </div>


        <footer className='absolute bottom-0 left-0 p-4 w-full bg-[#565f8f] text-white flex justify-center items-center' >
                <p>© 2023 VideoCorral - All Rights Reserved</p>    
        </footer>
        <MyLoader loader={loader} />
    </div>
  )
}

export default ForgotPassword