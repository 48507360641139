import { __assign } from "tslib";
export var AmbiguousRoleResolutionType;
(function (AmbiguousRoleResolutionType) {
    AmbiguousRoleResolutionType["AUTHENTICATED_ROLE"] = "AuthenticatedRole";
    AmbiguousRoleResolutionType["DENY"] = "Deny";
})(AmbiguousRoleResolutionType || (AmbiguousRoleResolutionType = {}));
export var CognitoIdentityProvider;
(function (CognitoIdentityProvider) {
    CognitoIdentityProvider.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CognitoIdentityProvider || (CognitoIdentityProvider = {}));
export var CreateIdentityPoolInput;
(function (CreateIdentityPoolInput) {
    CreateIdentityPoolInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(CreateIdentityPoolInput || (CreateIdentityPoolInput = {}));
export var IdentityPool;
(function (IdentityPool) {
    IdentityPool.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(IdentityPool || (IdentityPool = {}));
export var InternalErrorException;
(function (InternalErrorException) {
    InternalErrorException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InternalErrorException || (InternalErrorException = {}));
export var InvalidParameterException;
(function (InvalidParameterException) {
    InvalidParameterException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InvalidParameterException || (InvalidParameterException = {}));
export var LimitExceededException;
(function (LimitExceededException) {
    LimitExceededException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(LimitExceededException || (LimitExceededException = {}));
export var NotAuthorizedException;
(function (NotAuthorizedException) {
    NotAuthorizedException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(NotAuthorizedException || (NotAuthorizedException = {}));
export var ResourceConflictException;
(function (ResourceConflictException) {
    ResourceConflictException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ResourceConflictException || (ResourceConflictException = {}));
export var TooManyRequestsException;
(function (TooManyRequestsException) {
    TooManyRequestsException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TooManyRequestsException || (TooManyRequestsException = {}));
export var DeleteIdentitiesInput;
(function (DeleteIdentitiesInput) {
    DeleteIdentitiesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteIdentitiesInput || (DeleteIdentitiesInput = {}));
export var ErrorCode;
(function (ErrorCode) {
    ErrorCode["ACCESS_DENIED"] = "AccessDenied";
    ErrorCode["INTERNAL_SERVER_ERROR"] = "InternalServerError";
})(ErrorCode || (ErrorCode = {}));
export var UnprocessedIdentityId;
(function (UnprocessedIdentityId) {
    UnprocessedIdentityId.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UnprocessedIdentityId || (UnprocessedIdentityId = {}));
export var DeleteIdentitiesResponse;
(function (DeleteIdentitiesResponse) {
    DeleteIdentitiesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteIdentitiesResponse || (DeleteIdentitiesResponse = {}));
export var DeleteIdentityPoolInput;
(function (DeleteIdentityPoolInput) {
    DeleteIdentityPoolInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeleteIdentityPoolInput || (DeleteIdentityPoolInput = {}));
export var ResourceNotFoundException;
(function (ResourceNotFoundException) {
    ResourceNotFoundException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ResourceNotFoundException || (ResourceNotFoundException = {}));
export var DescribeIdentityInput;
(function (DescribeIdentityInput) {
    DescribeIdentityInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeIdentityInput || (DescribeIdentityInput = {}));
export var IdentityDescription;
(function (IdentityDescription) {
    IdentityDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(IdentityDescription || (IdentityDescription = {}));
export var DescribeIdentityPoolInput;
(function (DescribeIdentityPoolInput) {
    DescribeIdentityPoolInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DescribeIdentityPoolInput || (DescribeIdentityPoolInput = {}));
export var ExternalServiceException;
(function (ExternalServiceException) {
    ExternalServiceException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ExternalServiceException || (ExternalServiceException = {}));
export var GetCredentialsForIdentityInput;
(function (GetCredentialsForIdentityInput) {
    GetCredentialsForIdentityInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetCredentialsForIdentityInput || (GetCredentialsForIdentityInput = {}));
export var Credentials;
(function (Credentials) {
    Credentials.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(Credentials || (Credentials = {}));
export var GetCredentialsForIdentityResponse;
(function (GetCredentialsForIdentityResponse) {
    GetCredentialsForIdentityResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetCredentialsForIdentityResponse || (GetCredentialsForIdentityResponse = {}));
export var InvalidIdentityPoolConfigurationException;
(function (InvalidIdentityPoolConfigurationException) {
    InvalidIdentityPoolConfigurationException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(InvalidIdentityPoolConfigurationException || (InvalidIdentityPoolConfigurationException = {}));
export var GetIdInput;
(function (GetIdInput) {
    GetIdInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetIdInput || (GetIdInput = {}));
export var GetIdResponse;
(function (GetIdResponse) {
    GetIdResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetIdResponse || (GetIdResponse = {}));
export var GetIdentityPoolRolesInput;
(function (GetIdentityPoolRolesInput) {
    GetIdentityPoolRolesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetIdentityPoolRolesInput || (GetIdentityPoolRolesInput = {}));
export var MappingRuleMatchType;
(function (MappingRuleMatchType) {
    MappingRuleMatchType["CONTAINS"] = "Contains";
    MappingRuleMatchType["EQUALS"] = "Equals";
    MappingRuleMatchType["NOT_EQUAL"] = "NotEqual";
    MappingRuleMatchType["STARTS_WITH"] = "StartsWith";
})(MappingRuleMatchType || (MappingRuleMatchType = {}));
export var MappingRule;
(function (MappingRule) {
    MappingRule.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(MappingRule || (MappingRule = {}));
export var RulesConfigurationType;
(function (RulesConfigurationType) {
    RulesConfigurationType.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RulesConfigurationType || (RulesConfigurationType = {}));
export var RoleMappingType;
(function (RoleMappingType) {
    RoleMappingType["RULES"] = "Rules";
    RoleMappingType["TOKEN"] = "Token";
})(RoleMappingType || (RoleMappingType = {}));
export var RoleMapping;
(function (RoleMapping) {
    RoleMapping.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(RoleMapping || (RoleMapping = {}));
export var GetIdentityPoolRolesResponse;
(function (GetIdentityPoolRolesResponse) {
    GetIdentityPoolRolesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetIdentityPoolRolesResponse || (GetIdentityPoolRolesResponse = {}));
export var GetOpenIdTokenInput;
(function (GetOpenIdTokenInput) {
    GetOpenIdTokenInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetOpenIdTokenInput || (GetOpenIdTokenInput = {}));
export var GetOpenIdTokenResponse;
(function (GetOpenIdTokenResponse) {
    GetOpenIdTokenResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetOpenIdTokenResponse || (GetOpenIdTokenResponse = {}));
export var DeveloperUserAlreadyRegisteredException;
(function (DeveloperUserAlreadyRegisteredException) {
    DeveloperUserAlreadyRegisteredException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(DeveloperUserAlreadyRegisteredException || (DeveloperUserAlreadyRegisteredException = {}));
export var GetOpenIdTokenForDeveloperIdentityInput;
(function (GetOpenIdTokenForDeveloperIdentityInput) {
    GetOpenIdTokenForDeveloperIdentityInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetOpenIdTokenForDeveloperIdentityInput || (GetOpenIdTokenForDeveloperIdentityInput = {}));
export var GetOpenIdTokenForDeveloperIdentityResponse;
(function (GetOpenIdTokenForDeveloperIdentityResponse) {
    GetOpenIdTokenForDeveloperIdentityResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(GetOpenIdTokenForDeveloperIdentityResponse || (GetOpenIdTokenForDeveloperIdentityResponse = {}));
export var ListIdentitiesInput;
(function (ListIdentitiesInput) {
    ListIdentitiesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListIdentitiesInput || (ListIdentitiesInput = {}));
export var ListIdentitiesResponse;
(function (ListIdentitiesResponse) {
    ListIdentitiesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListIdentitiesResponse || (ListIdentitiesResponse = {}));
export var ListIdentityPoolsInput;
(function (ListIdentityPoolsInput) {
    ListIdentityPoolsInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListIdentityPoolsInput || (ListIdentityPoolsInput = {}));
export var IdentityPoolShortDescription;
(function (IdentityPoolShortDescription) {
    IdentityPoolShortDescription.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(IdentityPoolShortDescription || (IdentityPoolShortDescription = {}));
export var ListIdentityPoolsResponse;
(function (ListIdentityPoolsResponse) {
    ListIdentityPoolsResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListIdentityPoolsResponse || (ListIdentityPoolsResponse = {}));
export var ListTagsForResourceInput;
(function (ListTagsForResourceInput) {
    ListTagsForResourceInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTagsForResourceInput || (ListTagsForResourceInput = {}));
export var ListTagsForResourceResponse;
(function (ListTagsForResourceResponse) {
    ListTagsForResourceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ListTagsForResourceResponse || (ListTagsForResourceResponse = {}));
export var LookupDeveloperIdentityInput;
(function (LookupDeveloperIdentityInput) {
    LookupDeveloperIdentityInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(LookupDeveloperIdentityInput || (LookupDeveloperIdentityInput = {}));
export var LookupDeveloperIdentityResponse;
(function (LookupDeveloperIdentityResponse) {
    LookupDeveloperIdentityResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(LookupDeveloperIdentityResponse || (LookupDeveloperIdentityResponse = {}));
export var MergeDeveloperIdentitiesInput;
(function (MergeDeveloperIdentitiesInput) {
    MergeDeveloperIdentitiesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(MergeDeveloperIdentitiesInput || (MergeDeveloperIdentitiesInput = {}));
export var MergeDeveloperIdentitiesResponse;
(function (MergeDeveloperIdentitiesResponse) {
    MergeDeveloperIdentitiesResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(MergeDeveloperIdentitiesResponse || (MergeDeveloperIdentitiesResponse = {}));
export var ConcurrentModificationException;
(function (ConcurrentModificationException) {
    ConcurrentModificationException.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(ConcurrentModificationException || (ConcurrentModificationException = {}));
export var SetIdentityPoolRolesInput;
(function (SetIdentityPoolRolesInput) {
    SetIdentityPoolRolesInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(SetIdentityPoolRolesInput || (SetIdentityPoolRolesInput = {}));
export var TagResourceInput;
(function (TagResourceInput) {
    TagResourceInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TagResourceInput || (TagResourceInput = {}));
export var TagResourceResponse;
(function (TagResourceResponse) {
    TagResourceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(TagResourceResponse || (TagResourceResponse = {}));
export var UnlinkDeveloperIdentityInput;
(function (UnlinkDeveloperIdentityInput) {
    UnlinkDeveloperIdentityInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UnlinkDeveloperIdentityInput || (UnlinkDeveloperIdentityInput = {}));
export var UnlinkIdentityInput;
(function (UnlinkIdentityInput) {
    UnlinkIdentityInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UnlinkIdentityInput || (UnlinkIdentityInput = {}));
export var UntagResourceInput;
(function (UntagResourceInput) {
    UntagResourceInput.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UntagResourceInput || (UntagResourceInput = {}));
export var UntagResourceResponse;
(function (UntagResourceResponse) {
    UntagResourceResponse.filterSensitiveLog = function (obj) { return (__assign({}, obj)); };
})(UntagResourceResponse || (UntagResourceResponse = {}));
