
import Auth from '@aws-amplify/auth';

const baseUrl = 'https://api.videocorral.com';
export const registerContributor = baseUrl + '/contributors'; // POST
export const events = baseUrl + '/events';
export const getUploadUrl = baseUrl + '/events'; // PUT /{{eventId}}
const occasions = baseUrl + '/occasions';
const videos = baseUrl + '/videos';
const getEventVideos = baseUrl + '/events'; // GET /{{eventId}}/videos
const getEvent = baseUrl + '/events'; // GET /{{eventId}}
const startConvertJob = baseUrl + '/events'; // PUT /{{eventId}}
const inAppPurchase = baseUrl + '/in-app-purchase'

const uploadPhoto = baseUrl + '/uploadPhoto';

const getAccessToken = async () => {
    // Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
    const session = await Auth.currentSession();
    if (session == null) {
        return null
    }
    // console.log('session: '+JSON.stringify(session));
    let token = session.getAccessToken().getJwtToken()
    console.log('getAccessToken: ' + token);
    return token;
};

const Api = {
    startConvertJob: async function (eventId, onSuccess, onError) {
        apiCall(`${events}/${eventId}/conversion`, null, 'POST', await getDefaultHeaders(), onSuccess, onError)
    },

    getEventDetail: async function (eventId, onSuccess, onError) {
        apiCall(`${events}/${eventId}/detail`, null, 'GET', await getDefaultHeaders(), onSuccess, onError)
    },

    deleteVideo: async function (videoId, onSuccess, onError) {
        apiCall(videos + '/' + videoId, null, 'DELETE', await getDefaultHeaders(), onSuccess, onError)
    },

    // getRefreshToken: async function (onSuccess, onError) {
    //   let headers = {
    //     'Content-Type': ContentType,
    //     'Authorization': 'Bearer ' + GLOBAL.contAccessToken
    //   }
    //   apiCall(registerContributor, null, 'GET', headers, onSuccess, onError)
    // },

    // getUploadUrl: async function (videoName, eventId, onSuccess, onError) {
    //   let params = {
    //     fileName: videoName,
    //   }
    //   let headers = {
    //     'Content-Type': ContentType,
    //     'Authorization': 'Bearer ' + GLOBAL.contAccessToken
    //   }
    //   apiCall(getUploadUrl + '/' + eventId, params, 'PUT', headers, onSuccess, onError)
    // },

    // getEvent: async function (eventId, onSuccess, onError) {
    //   let headers = {
    //     'Content-Type': ContentType,
    //     'Authorization': 'Bearer ' + GLOBAL.contAccessToken
    //   }
    //   apiCall(getEvent + '/' + eventId, null, 'GET', headers, onSuccess, onError)
    // },

    // registerContributor: async function (referenceName, email, isSubscribing, onSuccess, onError) {
    //   let params = {
    //     referenceName: referenceName,
    //     email: email,
    //     isSubscribing: isSubscribing,
    //   }
    //   let headers = {
    //     'Content-Type': ContentType
    //   }
    //   apiCall(registerContributor, params, 'POST', headers, onSuccess, onError)
    // },

    getEventVideos: async function (eventId, onSuccess, onError) {
        apiCall(getEventVideos + '/' + eventId + '/videos', null, 'GET', await getDefaultHeaders(), onSuccess, onError)
    },

    getOccasions: async function (onSuccess, onError) {
        apiCall(occasions, null, 'GET', await getDefaultHeaders(), onSuccess, onError)
    },

    getEvents: async function (onSuccess, onError) {
        let accessToken = localStorage.getItem('accessToken')
        if (accessToken == null) {
            let interval = setInterval(async () => {
                if (accessToken != null && accessToken !== '') {
                    apiCall(events, null, 'GET', await getDefaultHeaders(), onSuccess, onError)
                    clearInterval(interval);
                }
            }, 500);
        }
        else {
            apiCall(events, null, 'GET', await getDefaultHeaders(), onSuccess, onError)
        }
    },

    createEvent: async function (occasionId, title, description, deadline, maxTimeInSeconds, onSuccess, onError) {
        let params = {
            title: title,
            description: description,
            deadline: deadline,
            maxTimeInSeconds: maxTimeInSeconds,
            occasionId: occasionId
        }
        apiCall(events, params, 'POST', await getDefaultHeaders(), onSuccess, onError)
    },

    deleteEvent: async function (eventId, onSuccess, onError) {
        apiCall(events + '/' + eventId, null, 'DELETE', await getDefaultHeaders(), onSuccess, onError)
    },

    // uploadPhoto: async function (photo, onSuccess, onError) {
    //     let params = new FormData();
    //     if (photo != null)
    //         params.append("photo", { name: 'photo', type: photo.mime, uri: Platform.OS === "android" ? photo.path : photo.path.replace("file://", "") });
    //     params.append('name', name);
    //     let headers = {
    //         'Content-Type': 'multipart/form-data',
    //     };
    //     apiCallFile(uploadPhoto, params, 'POST', headers, onSuccess, onError);
    // },

    getStripeClientSecret: async function (eventId, onSuccess, onError) {
        apiCall(inAppPurchase + '/create-payment/' + eventId, null, 'POST', await getDefaultHeaders(), onSuccess, onError)
    },

    completePayment: async function (eventId, onSuccess, onError) {
        apiCall(inAppPurchase + '/complete-payment/' + eventId, null, 'POST', await getDefaultHeaders(), onSuccess, onError)
    },

    getStripeSessionUrl: async function (eventId, onSuccess, onError) {
        apiCall(inAppPurchase + '/create-session/' + eventId, null, 'POST', await getDefaultHeaders(), onSuccess, onError)
    },
    downloadVideo: async function (url, fileName) {
        fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'video/mp4',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    fileName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

}

const apiCall = (url, params, method, headers, onSuccess, onError) => {
    // console.log('token: '+GLOBAL.accessToken);
    console.log('url: ' + url);
    if (params != null)
        console.log('sending: ' + JSON.stringify(params));
    fetch(url, {
        method: method,
        headers: headers,
        body: params == null ? params : JSON.stringify(params)
    })
        .then(response => response.json())
        .then(result => {
            console.log(JSON.stringify(result));
            onSuccess(result);
        })
        .catch(err => {
            console.log(err)
            onError(err);
        });
};

const getDefaultHeaders = async () => {
    return {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + await getAccessToken()
    }
}

const apiCallFile = (url, params, method, headers, onSuccess, onError) => {
    console.log('url: ' + url);
    fetch(url, {
        method: method,
        headers: headers,
        body: params
    })
        .then(response => response.json())
        .then(result => {
            console.log(JSON.stringify(result));
            onSuccess(result);
        })
        .catch(err => {
            console.log(err)
            onError(err);
        });
};

export default Api;
