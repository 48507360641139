const Utils = {
  checkMargin: (index:number) => {
    if (index === 3 || index === 7 || index === 11 || index === 15 || index === 19 || index === 23 || index === 27 || index === 31 || index === 35 || index === 39 || index === 43 || index === 47) {
      return 0
    }
    return 10
  },

  checkSession: () => {
    // console.log('check session');
    console.log(''+localStorage.getItem('login'));
    if (localStorage.getItem('login') == null) {
      console.log('not login');
      return false
      // const history = useHistory();
      // history.replace('/login')
      // return <Redirect to='/login' />;
    }
    else{
      // console.log('login');
      return true
    }
  },
}

export default Utils