import React, { useState, useEffect } from 'react';
import '../App.css';
import {MutatingDots as Loader} from "react-loader-spinner";
import useWindowDimensions from '../hooks/windowDimension';

interface MyLoader{
    loader:boolean
}
const MyLoader = ({loader}:MyLoader) => {
    if(!loader) return <></>
    return (
        <div className='absolute top-0 left-0 w-full h-full z-[100000000] bg-[#00000013] flex justify-center items-center'>
            <Loader
                type="MutatingDots"
                color="#ee3a55"
                secondaryColor="#565f90"
                height={100}
                width={100}
                visible={loader} />
        </div>
    );
}

export default MyLoader;