import React from "react";
import Layout from "../../components/Layout";

type Props = {};

const Notifications = (props: Props) => {
  return (
    <Layout>
      <div className="flex flex-col w-full items-center gap-5">
        <h1 className="text-2xl text-[#565f8f] font-bold tracking-wide">
          Notifications
        </h1>
      </div>
    </Layout>
  );
};

export default Notifications;
