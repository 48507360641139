import React, { useState, useRef } from "react";
import Layout from "../../components/Layout";
import emailIcon from "../../assets/images/email.png";
import { Switch } from "@mui/material";
type Props = {};

const Settings = (props: Props) => {
  const [email, setEmail] = useState<string>("");
  const [checked, setChecked] = useState<boolean>(false);
  const checkBoxRef = useRef<any>(null);
  return (
    <Layout>
      <div className="flex flex-col w-full items-center gap-5">
        <h1 className="text-2xl text-[#565f8f] font-bold tracking-wide">
          Settings
        </h1>
        <form className="w-full flex flex-col items-center gap-3">
          <h1 className="text-xl">Subscribe for Newsletter</h1>
          <div className="relative bg-white w-full h-[61px] rounded-md overflow-hidden">
            <input
              className="w-full h-full text-[#505050] rounded-md py-1 pr-4 pl-[50px] font-semibold  border-[1px] border-solid border-[#aeb5d9]"
              type="email"
              name="email"
              placeholder="Enter Email"
              id="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <img
              className="absolute top-[23px] left-4"
              src={emailIcon}
              alt="email"
            />
          </div>
          <button className="py-3 w-full bg-[#565f8f] text-white tracking-[1px]  rounded-md flex items-center justify-center text-[20px] font-semibold">
            Signup
          </button>

          <div className="flex w-full justify-start  mt-4 items-center gap-2">
            <label
              className="relative w-[50px] scale-75 cursor-pointer sm:scale-100 h-[23px] checkbox-label"
              htmlFor="checkbox"
            >
              <input
                id="checkbox"
                className="appereance-none transition-all duration-250 ease-in w-full h-full rounded-[11px] bg-[#a0a1a8] checked:bg-[#565f8f]"
                type="checkbox"
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />
              <div
                className={`w-[15px] transition-all duration-100 ease-in h-[15px] bg-white rounded-full absolute  ${
                  checked ? " top-1 right-1" : "top-1 left-1"
                }`}
              ></div>
            </label>
            <p className="text-sm ">Notify when a video has been uploaded</p>
          </div>

          <button className="py-3 w-full  mt-8 bg-[#ef3d55] text-white tracking-[1px]    rounded-md flex items-center justify-center text-[20px] font-semibold">
            Save
          </button>
        </form>
      </div>
    </Layout>
  );
};

export default Settings;
