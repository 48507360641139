import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import usericon from "../../assets/images/usericon.png";
import email from "../../assets/images/email.png";
import Auth from "@aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import * as Strings from "../../constants/strings";

type Props = {};

const Profile = (props: Props) => {
  const navigate = useNavigate();
  let user: any = localStorage.getItem("user");
  if (user != null) {
    user = JSON.parse(user);
  } else {
    user = {};
  }
  const [name, setName] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState("");
  const [showMsg, setShowMsg] = useState(false);
  const [msg, setMsg] = useState("");

  const logoutUser = () => {
    Auth.signOut();
    localStorage.removeItem("login");
    localStorage.removeItem("user");
    localStorage.clear();
    setTimeout(() => {
      navigate("/login");
    }, 1000);
  };
  const updateProfile = () => {
    if (name === "") {
      return;
    }
    setLoader(true);
    Auth.currentAuthenticatedUser()
      .then((user_) => {
        Auth.updateUserAttributes(user_, { name: name })
          .then((data) => {
            //console.log(data);
            setLoader(false);

            user.name = name;
            localStorage.setItem("user", JSON.stringify(user));
            setShowMsg(true);
            setMsg(Strings.txt64);
            setTimeout(() => {
              setShowMsg(false);
              setMsg("");
            }, 2000);
          })
          .catch((err) => {
            setLoader(false);
            console.log(err);
          });
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      });
  };
  return (
    <Layout>
      <div className="flex flex-col gap-5 items-center">
        <h1 className="text-2xl font-semibold tracking-wide capitalize text-[#565f8f]">
          My Profile
        </h1>

        <div className="w-full space-y-3 overflow-hidden">
          <div className="w-full bg-white p-3 sm:p-4 rounded-md flex items-center gap-5">
            <img src={usericon} alt="" />
            <input
              className=" text-md sm:text-lg text-[#505050] border-none outline-none"
              placeholder={user.name}
              readOnly={user.social}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="w-full bg-white p-3 sm:p-4 rounded-md flex items-center gap-5">
            <img src={email} alt="" />
            <input
              className=" text-md sm:text-lg text-[#505050] border-none outline-none"
              placeholder={user.email}
              readOnly
            />
          </div>
        </div>

        {!user.social && (
          <button
            onClick={() => updateProfile()}
            className="w-full py-2 sm:py-3 bg-[#565f8f] text-white text-[18px] xsm:text-[20px] sm:text-[22px] font-semibold sm:font-bold rounded-md"
          >
            Update
          </button>
        )}
        <button
          onClick={() => logoutUser()}
          className="w-full py-2 sm:py-3 bg-[#ef3d55] text-white text-[18px] xsm:text-[20px] sm:text-[22px] font-semibold sm:font-bold rounded-md"
        >
          Logout
        </button>
      </div>
    </Layout>
  );
};

export default Profile;
