import { __awaiter, __generator } from "tslib";
export var deserializerMiddleware = function (options, deserializer) { return function (next, context) { return function (args) { return __awaiter(void 0, void 0, void 0, function () {
    var response, parsed;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, next(args)];
            case 1:
                response = (_a.sent()).response;
                return [4 /*yield*/, deserializer(response, options)];
            case 2:
                parsed = _a.sent();
                return [2 /*return*/, {
                        response: response,
                        output: parsed,
                    }];
        }
    });
}); }; }; };
