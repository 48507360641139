export const themeDark = '#EF3D55';
export const theme = '#EF3D55';
export const themeLight = '#cce2ff';
export const loaderColor = themeDark;
export const headerTint = 'white';

export const facebookButton = '#3B5998'
export const googleButton = '#DB4437'
export const tabBar = '#232D61';
export const ocasionBackground = '#F2F2F7';
export const button = theme;
export const background = '#f3f3f7';
export const fieldBorder = '#EF3D55';
export const fieldBackground = 'white'
export const textLight = '#AAAEC6';
export const textMedium = '#565F8F';
export const textDark = '#565F8F';
export const gray = '#8E8E93';

export const floatingCross = 'rgba(7, 8, 8, 0.7)';
export const floatingCrossTint = 'white';
export const textError = 'red';
export const thumbnail = '#D8D8D8';
export const lineLight = '#F2F2F7';
export const lineMedium = 'gray';
export const lineDark = 'black';
export const iconLight = '#c2bdbd';
export const iconMedium = 'gray';
export const iconDark = theme;
export const thumbTrackOn = '#04B431';
export const thumbTrackOff = '#c2bdbd';
export const buttonCancel = themeDark;

export const black = 'black';
export const white = 'white';
export const red = '#B40404';
export const green = 'green';
export const blue = '#1373e2';
export const orange = '#cf8b32';
export const purple = 'purple';
export const yellow = 'yellow';
export const pink = 'pink';